import { API } from "../../middleware";
import i18next from "i18next";
import { toast } from "react-toastify";
import {
  SET_ROW_COUNT,
  SET_CURRENT_PAGE,
  SET_SORT_BY,
  SET_SELECTED_VEHICLE_STATUS,
  GET_VEHICLE_LIST,
  SET_VIEW_FOR_LIST,
  SET_VEHICLE_FOR_ASSIGN,
  SET_FILTER_OBJ,
  UPDATE_VEHICLE_LIST,
  SET_INSPECTION_REPORT,
  GET_VEHICLE_DETAILS_BY_VEHICLELISTINGID,
  GET_QC_REJECTION_STATUS,
  POST_QC_REJECTION_STATUS,
  GET_VEHICLE_LISTING_IMAGES_BY_ID,
  POST_OVERALL_QC_STATUS,
  GET_VEHICLE_LISTING_DETAILS_BY_ID,
  GET_VEHICLE_LISTING_IMAGES_BY_ID_SUCCESS,
  GET_VEHICLE_DELETION_REASONS,
  DELETE_VEHICLE_SUCCESS,
  GET_USER_SEARCH,
  SET_SELECTED_DMS_STATUS,
  FETCH_VEHICLE_DATA,
  FETCH_VEHICLE_DETAILS,
  GET_VEHICLE_DESCRIPTION,
  UPDATE_VEHICLE_LISTING_IMAGES_STATUS,
  UPDATE_QC_DETAILS,
  GET_QC_DETAILS,
  CLEAR_VEHICLE_DETAILS,
  GET_MODEL_YEAR_DATA,
  GET_VEHICLE_MAKE,
  GET_SEARCH_RESULT,
  GET_VEHICLE_TRIM,
  GET_VEHICLE_MODEL,
  GET_LISTING_IMAGES,
  GET_LISTING_DOCUMENT,
  UPDATE_LISTING_IMAGES,
  GET_INSPECTOR_LISTING_IMAGES,
  GET_SPIN_CAR_IMAGES,
  GET_SPIN_CAR_360_IMAGES,
  GET_VEHICLE_PROFILE,
  GET_VEHICLE_PROFILE_DATA,
  SET_VEHICLE_PROFILE_FILTER,
  GET_ARTIFACT_BY_PROFILE_ID,
  GET_QC_REJECTION_ID,
  GET_MANUFACTURER_LISTING_IMAGES,
  GET_VEHICLE_BEFORE_AFTER_IMAGE,
  GET_APPOINTMENT_FILTER_DATA,
  SET_APPOINTMENT_FILTER,
  GET_APPOINTMENT_DETAILS,
  GET_DYNAMIC_REPORTS,
  GET_DYNAMIC_REPORTS_DATA,
  UPDATE_VEHICLE_LISTING_DOCUMENT_STATUS,
  GET_VEHICLE_EBROCHURE,
  GET_FAV_REPORTS,
  GET_COLOR_VARIANT,
  GET_COLOR_VARIANT_BY_ID,
  GET_TRIM_BY_ID,
  GET_VEHICLE_PROFILE_BY_ID,
  CLEAR_VEHICLE_PROFILE_DATA,
  SET_DEALS_FILTER_PAYLOAD,
  GET_DEAL_STATUS,
  SET_DEALER_VEHICLES_FILTER,
  GET_DEALER_VEHICLES_INFO,
  GET_ALL_REPORT_TYPES,
  GET_FULFILLED_BY_DATA,
  GET_IMPORTED_BY_DATA,
  SET_WISHLIST_LEADS_FILTER,
  GET_VEHICLE_LISTING_STATUS,
  SET_LEADS_FILTER_DETAILS,
} from "../types";
import { hideLoader, showLoader } from "./applicationAction";
import MessageBox, {
  MessageBoxType,
} from "../../components/common/message-box";
import { ArtifactType } from "../../utilities/constants/global.constants";
import axios from "axios";

export const setRowCount = (data) => ({
  type: SET_ROW_COUNT,
  payload: data,
});

export const setCurrentPage = (data) => ({
  type: SET_CURRENT_PAGE,
  payload: data,
});

export const setSortBy = (data) => ({
  type: SET_SORT_BY,
  payload: data,
});

export const setSelectedVehicleStatus = (data) => ({
  type: SET_SELECTED_VEHICLE_STATUS,
  payload: data,
});

export const setSelectedDMSStatus = (data) => ({
  type: SET_SELECTED_DMS_STATUS,
  payload: data,
});

export const setAllVehicleList = (data) => ({
  type: GET_VEHICLE_LIST,
  payload: data,
});

export const setVehicleListView = (data) => ({
  type: SET_VIEW_FOR_LIST,
  payload: data,
});

export const setVehiclesForAssign = (data) => ({
  type: SET_VEHICLE_FOR_ASSIGN,
  payload: data,
});

export const setFilterObject = (data) => ({
  type: SET_FILTER_OBJ,
  payload: data,
});

export const setInspectionReport = (data) => ({
  type: SET_INSPECTION_REPORT,
  payload: data,
});

export const getVehicleListStatus = (lan) => {
  return () => {
    return API.get("/api/QC/status", {
      params: { languageId: lan },
    });
  };
};

export const getMyVehicle = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: FETCH_VEHICLE_DATA });
    return API.post("/api/Vehicle/myvehicles", data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch(setAllVehicleList(response.data));
      })
      .catch((error) => {
        dispatch(hideLoader());
        return error;
      });
  };
};

export const downloadFile = (fileName) => {
  return () => {
    return API.get("/api/Listing/downloadFile", {
      params: {
        fileName,
      },
    }).catch((error) => {
      return error;
    });
  };
};

export const updateQCDetails = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_QC_DETAILS, payload });
};

export const getQcDetails = (id, lan) => {
  return () => {
    return API.get("/api/QC/qcstatusdetails", {
      params: { VehicleListingId: id, LanguageId: lan },
    });
  };
};

export const getQCDetails = (id, lan) => async (dispatch) => {
  try {
    const response = await API.get("/api/QC/qcstatusdetails", {
      params: { VehicleListingId: id, LanguageId: lan },
    });
    dispatch({ type: GET_QC_DETAILS, payload: response.data });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getInspectionDetails = (id, lan) => {
  return () => {
    return API.get("/api/Inspection/inspectionreport", {
      params: { VehicleListingId: id, LanguageId: lan },
    });
  };
};

export const assignVehicleToDMSSalesperson = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    return API.post("/api/dms/vehicleassignment", data)
      .then((res) => {
        dispatch(hideLoader());
        dispatch({ type: UPDATE_VEHICLE_LIST });
        if (res.data && res.status === 200) {
          toast.success(i18next.t("VEHICLE_ASSIGNMENT_SUCCESS"));
          return Promise.resolve(res);
        } else {
          toast.error(i18next.t("VEHICLE_ASSIGNMENT_FAIL"));
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        return error;
      });
  };
};

export const getInspectionReport = (vehicleId) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Inspection/inspectionreport?vehicleListingId=${vehicleId}`
    );
    dispatch(setInspectionReport(response.data));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getVehicleDetailsById =
  (languageId, vehicleId) => async (dispatch) => {
    try {
      const response = await API.get(
        `/api/Vehicle/vehicle?languageId=${languageId}&vehicleListingId=${vehicleId}`
      );
      dispatch(getVehicleDetailsByIdData(response));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getVehicleDetailsByIdData = (data) => ({
  type: GET_VEHICLE_DETAILS_BY_VEHICLELISTINGID,
  payload: data,
});

export const getQcRejectionStatus = (languageId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/Vehicle/qualitycheckrejectionreason?languageId=${languageId}`
    );
    dispatch(hideLoader());
    dispatch({
      type: GET_QC_REJECTION_STATUS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const postQcRejectionReason =
  (vehicleId, reasonId, comment, status) => async (dispatch) => {
    try {
      const response = await API.post(
        `/api/Vehicle/qualitycheckrejectionreason?vehicleListingId=${vehicleId}&qualityCheckRejectionReasonId=${reasonId}&rejectionSummary=${comment}&qualityCheckStatus=${status}`
      );
      dispatch(setQcRejectionReason(response));
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const setQcRejectionReason = (data) => ({
  type: POST_QC_REJECTION_STATUS,
  payload: data,
});

export const updateVehicleListingArtifactImagesStatus =
  (payload) => async (dispatch) => {
    dispatch({ type: UPDATE_VEHICLE_LISTING_IMAGES_STATUS, payload });
  };

export const clearVehicleListingDetails = () => (dispatch) => {
  dispatch({
    type: CLEAR_VEHICLE_DETAILS,
  });
};

export const getVehicleListingArtifactImagesById =
  (languageId, vehicleId) => async (dispatch) => {
    try {
      dispatch({
        type: GET_VEHICLE_LISTING_IMAGES_BY_ID,
        payload: [],
      });
      dispatch(showLoader());
      const response = await API.get(
        `/api/Vehicle/listingimagesartifacts?languageId=${languageId}&vehicleListingId=${vehicleId}`
      );
      dispatch(hideLoader());
      let listingImages = response.data;

      if (
        listingImages.length > 0 &&
        listingImages.filter((x) => x.IsVisibleOnPortal).length === 0
      ) {
        const findValue = listingImages.findIndex(
          (x) =>
            x.ArtifactTypeKey === ArtifactType.PROFILE_VIEW ||
            x.ArtifactTypeKey === ArtifactType.INSPECTION_PROFILE_VIEW ||
            x.ArtifactTypeKey === ArtifactType.SPIN_CAR_PROFILE_VIEW ||
            x.ArtifactTypeKey === ArtifactType.MFG_PROFILE_VIEW
        );
        if (findValue !== -1) {
          listingImages[findValue].IsProfileImage = true;
          listingImages[findValue].IsVisibleOnPortal = true;
        }
      }

      dispatch({
        type: GET_VEHICLE_LISTING_IMAGES_BY_ID_SUCCESS,
        payload: listingImages,
      });
    } catch (error) {
      return error;
    }
  };
const getImageArtifacts = (vehicleListingId, languageId) => {
  return API.get("/api/Vehicle/listingimagesartifacts", {
    params: {
      languageId,
      vehicleListingId,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

const getInspectionReports = (vehicleListingId) => {
  return API.get("/api/Inspection/inspectionreport", {
    params: {
      vehicleListingId,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      throw err;
    });
};
export const validateVehicleAssets = (vehicleIds, languageId) => async () => {
  let isValid = false;
  const inspectionArray = vehicleIds.map((id) => getInspectionReports(id));
  const inspectionResponses = await Promise.all(inspectionArray);
  isValid = inspectionResponses.findIndex((x) => !x.ReportUrl) === -1;
  if (isValid) {
    const promiseArray = vehicleIds.map((id) =>
      getImageArtifacts(id, languageId)
    );
    const artifactResponses = await Promise.all(promiseArray);
    isValid = artifactResponses.every((x) => {
      if (
        isValid &&
        x &&
        x.length > 0 &&
        x.findIndex((y) => y.ArtifactTypeKey === "spincar-360-view") !== -1
      )
        return true;
      else return false;
    });
  }
  return isValid;
};

/**
 * This method is responsible to execute http PUT request to update overall QC status.
 * * Execute API - /api/QC/updateqcstatus
 */
export const postOverallQcStatus = (data, history) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put("/api/QC/updateqcstatus", data);
    dispatch(hideLoader());
    dispatch(setOverallQcStatus(response));
    if (response.status === 200) {
      if (response.data.IsSuccess) {
        if (response.data.MessageCode === "QC_STATUS_UPDATE_SUCCESS") {
          toast.success(i18next.t("QC_STATUS_UPDATE_SUCCESS"));
        }
      } else {
        if (response.data.MessageCode === "VEHICLE_ALREADY_PROCEED") {
          toast.error(i18next.t("VEHICLE_ALREADY_PROCEED"));
        } else {
          toast.error(i18next.t("QC_STATUS_UPDATE_FAILED"));
        }
      }
      history.push("/vehicles");
    }
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const setOverallQcStatus = (data) => ({
  type: POST_OVERALL_QC_STATUS,
  payload: data,
});

/***
 * This method is responsible to call GET request to get vehicle details by vehicle listing id.
 * @param {number} languageId - The language id
 * @param {number} vehicleId - The vehicle listing id
 * @returns Vehicle details
 */
export const getVehicleListingDetailsById =
  (languageId, vehicleId) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_VEHICLE_DETAILS });
      const response = await API.get(
        `/api/Vehicle/vehiclelisting?languageId=${languageId}&vehicleListingId=${vehicleId}`
      );
      dispatch({
        type: GET_VEHICLE_LISTING_DETAILS_BY_ID,
        payload: response.data,
      });
      return Promise.resolve(response);
    } catch (error) {
      return error;
    }
  };

export const getUserSearch = (searchkey) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/usersearch?searchKey=${searchkey}`
    );

    dispatch({ type: GET_USER_SEARCH, payload: response.data });
    if (!response.data.IsSucess) {
      toast.error(i18next.t("BUYER_NOT_FOUND"));
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const saveSoldTo = (payload) => async () => {
  try {
    const response = await API.put(`api/Vehicle/savesoldto`, payload);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadImageFile = (data) => async () => {
  try {
    const response = await API.post("/api/Vehicle/uploadlistingartifact", data);
    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

export const deleteImageFile = (data) => async () => {
  try {
    const response = await API.delete("/api/Vehicle/deletelistingartifact", {
      data,
    });

    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

/***
 * This method is responsible to call GET request to get vehicle details by vehicle listing id.
 * @param {number} languageId - The language id
 * @returns Vehicle deletion reasons
 */
export const getVehicleDeletionReasons = (languageId) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/deletionreasons?languageId=${languageId}`
    );
    dispatch({
      type: GET_VEHICLE_DELETION_REASONS,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};

export const deleteVehicleByVehicleListingId =
  (vehicleListingId, deletionReasonId, deletionSummary) => async (dispatch) => {
    try {
      const response = await API.delete(
        `/api/Vehicle/vehicle?vehicleListingId=${vehicleListingId}&deletionReasonId=${deletionReasonId}&deletionSummary=${deletionSummary}`
      );
      if (response.data) {
        dispatch({ type: DELETE_VEHICLE_SUCCESS });
        toast.success(i18next.t("VEHICLE_DELETED"));
      } else {
        toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
      }
      return Promise.resolve(response);
    } catch (error) {
      toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
      return Promise.reject(error);
    }
  };

export const approveRejectInspection = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      `/api/inspection/approveRejectInspection`,
      payload
    );
    dispatch({ type: UPDATE_VEHICLE_LIST });
    dispatch(hideLoader());
    if (response.data.MessageCode === "MSG_INSPECTION_APPROVED") {
      toast.success(i18next.t("MSG_INSPECTION_APPROVED"));
    }
    if (response.data.MessageCode === "MSG_INSPECTION_REJECTED") {
      toast.error(i18next.t("MSG_INSPECTION_REJECTED"));
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const clearVehicleDescription = () => (dispatch) => {
  dispatch({
    type: GET_VEHICLE_DESCRIPTION,
    payload: [],
  });
};

export const getVehicleDescription =
  (yid, mkid, mid, sid) => async (dispatch) => {
    try {
      const response = await API.get("/api/Vehicle/descriptionbymymmt", {
        params: {
          modelYearCode: yid,
          makeCode: mkid,
          modelCode: mid,
          specCode: sid,
        },
      });
      dispatch({
        type: GET_VEHICLE_DESCRIPTION,
        payload: response.data,
      });
    } catch (error) {
      return error;
    }
  };

export const saveVerifyDetails = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(`/api/Vehicle/saveverifiedvehicle`, data);
    if (response.data.IsSuccess) {
      dispatch(hideLoader());
      await MessageBox.open({
        content: i18next.t("SAVED_VERIFIED_VEHICLE_SUCCESS"),
        type: MessageBoxType.Message,
      });
    }
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};
export const downloadMojazVehicleReport = (listingId) => async () => {
  try {
    const response = await API.get(
      `/api/Vehicle/downloadmojazvehiclereport?vehicleListingId=${listingId}`,
      {
        responseType: "blob",
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateVehicleFeature = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(`/api/Vehicle/feature`, data);
    dispatch(hideLoader());
    if (response.data) {
      toast.success(i18next.t("FEATURE_UPDATE_SUCCESS"));
    } else {
      toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    }
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};
export const getModelYear = () => async (dispatch) => {
  try {
    const response = await API.get(`api/vehicle/modelyears`);

    dispatch({
      type: GET_MODEL_YEAR_DATA,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};
export const getInspectionLocation = (languageId) => async () => {
  try {
    const response = await API.get(
      `/api/Vehicle/petrominlocations?languageId=${languageId}`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getVehicleMake = (languageId) => async (dispatch) => {
  try {
    const response = await API.get(
      `api/vehicle/makes?languageId=${languageId}`
    );
    dispatch({
      type: GET_VEHICLE_MAKE,
      payload: response.data,
    });
    return Promise.resolve(response);
  } catch (error) {
    return error;
  }
};

export const getSearchResult = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/Vehicle/makemodeltrim?languageId=${payload.languageId}&makeSearchKey=${payload.makeSearchKey}&modelSearchKey=${payload.modelSearchKey}&trimSearchKey=${payload.trimSearchKey}`
    );
    dispatch(hideLoader());

    dispatch({
      type: GET_SEARCH_RESULT,
      payload: response.data,
    });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};
export const getVehicleModel = (payload) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/modelsbymakeId?languageId=${payload.languageId}&makeId=${payload.makeId}`
    );
    dispatch({
      type: GET_VEHICLE_MODEL,
      payload: response.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};
export const getVehicleTrim = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/Vehicle/trimsbymodelIdmakeId?modelId=${payload.modelId}&makeId=${payload.makeId}&languageId=${payload.languageId}`
    );
    dispatch(hideLoader());
    dispatch({
      type: GET_VEHICLE_TRIM,
      payload: response.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const setTrimData = () => (dispatch) => {
  dispatch({
    type: GET_VEHICLE_TRIM,
    payload: [],
  });
};

export const setModelData = () => (dispatch) => {
  dispatch({
    type: GET_VEHICLE_MODEL,
    payload: [],
  });
};

export const addMakeModelTrim = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(`/api/Vehicle/addmakemodeltrim`, payload);
    dispatch(hideLoader());

    if (response.data.IsSuccess) {
      toast.success(i18next.t("DATA_ADDED_SUCCESS"));
    } else if (
      response.data.MessageCode === "MAKEMODELTRIM_COMBINATION_ALREADY_EXISTS"
    ) {
      toast.warning(i18next.t("MAKEMODELTRIM_COMBINATION_ALREADY_EXISTS"));
    } else {
      toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    }
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const updateMakeModelTrim = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      `/api/Vehicle/updatemakemodeltrim`,
      payload
    );
    dispatch(hideLoader());
    if (response.data.IsSuccess) {
      toast.success(i18next.t("DATA_UPDATE_SUCCESS"));
    } else {
      toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    }
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getVehicleListingImages =
  ({ languageId, vehicleListingId, bodyTypeCode }) =>
  async (dispatch) => {
    try {
      const response = await API.get(
        `/api/vehicle/listingimages?languageId=${languageId}&&vehicleListingId=${vehicleListingId}`,
        {
          params: {
            bodyTypeCode: bodyTypeCode !== 0 ? bodyTypeCode : null,
          },
        }
      );
      dispatch({
        type: GET_LISTING_IMAGES,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    } catch (error) {
      return error;
    }
  };

export const getVehicleInspectorListingImages =
  (payload) => async (dispatch) => {
    try {
      const response = await API.get(
        `/api/vehicle/listingimages?artifactCategory=${payload.artifactCategory}&&languageId=${payload.languageId}&&vehicleListingId=${payload.vehicleListingId}`
      );
      dispatch({
        type: GET_INSPECTOR_LISTING_IMAGES,
        payload: response.data,
      });
    } catch (error) {
      return error;
    }
  };

export const getVehicleSpinCarImages = (payload) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/vehicle/listingimages?artifactCategory=${payload.artifactCategory}&&languageId=${payload.languageId}&&vehicleListingId=${payload.vehicleListingId}`
    );
    dispatch({
      type: GET_SPIN_CAR_IMAGES,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};

export const getVehicleSpinCar360Images = (payload) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/vehicle/listingimages?artifactCategory=${payload.artifactCategory}&&languageId=${payload.languageId}&&vehicleListingId=${payload.vehicleListingId}`
    );
    dispatch({
      type: GET_SPIN_CAR_360_IMAGES,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};

export const updateListingImages = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_LISTING_IMAGES,
    payload: data,
  });
};

export const getVehicleListingDocument = (payload) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/listingdocuments?languageId=${payload.languageId}&&vehicleListingId=${payload.vehicleListingId}`
    );
    dispatch({
      type: GET_LISTING_DOCUMENT,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};
export const verifyMakeModelTrim = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      `/api/Vehicle/verifyyearmakemodeltrim`,
      data
    );
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const updateDealerVehicleDetails = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(`api/vehicle/dealervehicledetails`, data);
    dispatch(hideLoader());
    if (response.data.MessageCode === "UPDATEDEALERVEHICLE_SUCCESS") {
      toast.success(i18next.t("UPDATEDEALERVEHICLE_SUCCESS"));
    } else {
      toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    }
    return Promise.resolve(response);
  } catch (err) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    console.error(err);
  }
};

export const mojazvehiclereport = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(`/api/Vehicle/mojazvehiclereport`, data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (err) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    console.error(err);
  }
};

export const updateVehicleListingStatus = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(`api/vehicle/vehiclelistingstatus`, data);
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (err) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    console.error(err);
  }
};

export const addQualityCheckRejectionReason = (data) => async (dispatch) => {
  try {
    const response = await API.post(
      `/api/QC/addqualitycheckrejectionreason`,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    console.error(err);
  }
};
export const getVehicleDetails = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(`/api/vehicle/vin?vin=${payload}`);
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const saveVehicleProfile = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(`/api/VehicleProfile/create`, payload);
    dispatch(hideLoader());
    if (response.data.IsSuccess) {
      toast.success(i18next.t("VEHICLE_PROFILE_SAVED"));
    } else {
      if (response.data.MessageCode === "VEHICLEPROFILE_ALREADY_EXISTS") {
        toast.warn(i18next.t("VEHICLEPROFILE_ALREADY_EXISTS"));
      } else {
        toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
      }
    }
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getVehicleProfile =
  ({ selectedLanguage, GridPageSize, pageNumber, data }) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.get(`/api/VehicleProfile/get`, {
        params: data
          ? {
              languageId: selectedLanguage,
              pageSize: GridPageSize,
              pageNumber: pageNumber,
              makeId: data.makeId ? data.makeId : null,
              modelId: data.modelId ? data.modelId : null,
              trimId: data.trimId ? data.trimId : null,
              modelYearId: data.modelYearId ? data.modelYearId : null,
              colorVariantId: data.colorVariantId ? data.colorVariantId : null,
            }
          : {
              languageId: selectedLanguage,
              pageSize: GridPageSize,
              pageNumber: pageNumber,
            },
      });
      dispatch({ type: GET_VEHICLE_PROFILE, payload: response.data });
    } catch (error) {
      return error;
    } finally {
      dispatch(hideLoader());
    }
  };

export const setVehicleProfileData = (data) => ({
  type: GET_VEHICLE_PROFILE_DATA,
  payload: data,
});

export const setVehicleProfileFilterData = (data) => ({
  type: SET_VEHICLE_PROFILE_FILTER,
  payload: data,
});

export const setVehicleArtifact = (data) => ({
  type: GET_ARTIFACT_BY_PROFILE_ID,
  payload: data,
});

export const getArtifactsByProfileId = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `api/VehicleProfile/getartifacts?languageId=${payload.languageId}&id=${payload.id}&type=${payload.type}`
    );
    dispatch(hideLoader());
    dispatch(setVehicleArtifact(response.data));
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const deleteVehicleSpecArtifact = (data) => async () => {
  try {
    const response = await API.delete(
      `/api/VehicleProfile/artifact?artifactId=${data.artifactId}&id=${data.id}&type=${data.type}`
    );
    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

export const updateProfileStatus = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      `/api/VehicleProfile/updateprofilestatus`,
      payload
    );
    dispatch(hideLoader());

    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const uploadVehicleProfileArtifact = (data) => async () => {
  try {
    const response = await API.post("/api/VehicleProfile/artifact", data);
    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

export const saveQCRejectionStatus = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      "/api/QC/addupdatequalitycheckrejectionreason",
      payload
    );
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const getQCRejectionStatusById = (id) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/QC/qualitycheckrejectionreasonbyid?qualityCheckRejectionReasonId=${id}`
    );
    dispatch({ type: GET_QC_REJECTION_ID, payload: response.data });
  } catch (error) {
    return error;
  }
};

export const getManufacturerListingImages = (payload) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/vehicle/listingimages?artifactCategory=${payload.artifactCategory}&&languageId=${payload.languageId}&&vehicleListingId=${payload.vehicleListingId}`
    );
    dispatch({
      type: GET_MANUFACTURER_LISTING_IMAGES,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};

export const getVehicleBeforeAfterImage = (payload) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/vehicle/listingimages?artifactCategory=${payload.artifactCategory}&&languageId=${payload.languageId}&&vehicleListingId=${payload.vehicleListingId}`
    );
    dispatch({
      type: GET_VEHICLE_BEFORE_AFTER_IMAGE,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};

export const getVehicleEBrochure = (payload) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/vehicle/listingdocuments?artifactCategory=${payload.artifactCategory}&&languageId=${payload.languageId}&&vehicleListingId=${payload.vehicleListingId}`
    );
    dispatch({
      type: GET_VEHICLE_EBROCHURE,
      payload: response.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const saveDescription = (data) => async () => {
  try {
    const response = await API.post("/api/Vehicle/savedescription", data);
    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

export const getMyCarValueVehicleListing = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      "/api/Vehicle/mycarvaluewithlocalcodes",
      payload
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const setAppointmentData = (data) => ({
  type: GET_APPOINTMENT_FILTER_DATA,
  payload: data,
});

export const setAppointmentFilterData = (data) => ({
  type: SET_APPOINTMENT_FILTER,
  payload: data,
});

export const fetchPetrominLocation = (data) => {
  return () => {
    return API.get("/api/vehicle/petrominlocations", {
      params: data,
    })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return error;
      });
  };
};

export const getAppointmentData = ({
  selectedLanguage,
  GridPageSize,
  pageNumber,
  data,
  searchKey,
}) => {
  return (dispatch) => {
    dispatch(showLoader());
    return API.get("/api/Inspection/appointments", {
      params: data
        ? {
            languageId: selectedLanguage,
            pageSize: GridPageSize,
            pageNumber: pageNumber,
            startDate: data.appointmentCreatedDate.min
              ? data.appointmentCreatedDate.min
              : null,
            endDate: data.appointmentCreatedDate.max
              ? data.appointmentCreatedDate.max
              : null,
            cityId: data.cityId ? data.cityId : null,
            locationId: data.locationId ? data.locationId : null,
            SearchKey: searchKey,
          }
        : {
            languageId: selectedLanguage,
            pageSize: GridPageSize,
            pageNumber: pageNumber,
            SearchKey: searchKey,
          },
    })
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: GET_APPOINTMENT_DETAILS,
          payload: response.data,
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        dispatch(hideLoader());
        return error;
      });
  };
};

export const rescheduleAppointment = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      `/api/Inspection/appointmentreschedule`,
      payload
    );
    dispatch(hideLoader());

    if (response.data.MessageCode === "INSPECTION_REBOOKING_SUCCESS") {
      toast.success(i18next.t("INSPECTION_REBOOKING_SUCCESS"));
    } else if (
      response.data.MessageCode === "INSPECTION_REBOOKING_NOT_ALLOWED"
    ) {
      toast.error(i18next.t("INSPECTION_REBOOKING_NOT_ALLOWED"));
    } else {
      toast.error(i18next.t("INSPECTION_REBOOKING_FAILURE"));
    }

    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getAppointment = (data) => async (dispatch) => {
  try {
    const response = await API.get(`/api/Inspection/appointments`, {
      params: data,
    });
    dispatch({
      type: GET_APPOINTMENT_DETAILS,
      payload: response.data,
    });
  } catch (error) {
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

export const cancelAppointment = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      `/api/inspection/cancelappointment`,
      payload
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};
export const getDynamicReports = (key, userID) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/Vehicle/dynamicreports/getall?userId=${userID ? userID : 0}`,
      {
        params: key,
      }
    );
    dispatch({ type: GET_DYNAMIC_REPORTS, payload: response.data });
    dispatch(hideLoader());
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const saveDynamicReports = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put("/api/Vehicle/dynamicreports/save", payload);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

export const updateVehicleListingArtifactDocumentStatus =
  (payload) => async (dispatch) => {
    dispatch({ type: UPDATE_VEHICLE_LISTING_DOCUMENT_STATUS, payload });
  };

export const downloadDocumentArtifact = (listingID, artifactID) => async () => {
  try {
    const response = await API.get(`/api/Vehicle/downloaddocumentartifact`, {
      params: {
        vehicleListingId: listingID,
        vehicleListingArtifactId: artifactID,
      },
      responseType: "blob",
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const deleteReport = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.delete("/api/Vehicle/dynamicreports/delete", {
      data: payload,
    });
    if (response.data && response.data.IsSuccess) {
      dispatch(getDynamicReports());
      toast.success(i18next.t("REPORT_DELETED_SUCCESS"));
    } else {
      toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    }
    dispatch(hideLoader());
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const getDynamicReportsData = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      `/api/Vehicle/dynamicreports/getdynamicdatabyid`,
      payload
    );
    dispatch(setDynamicReportData(response.data));
    dispatch(hideLoader());
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return Promise.reject(error);
  }
};

export const setDynamicReportData = (data) => ({
  type: GET_DYNAMIC_REPORTS_DATA,
  payload: data,
});
export const rvApprovalVehicle = (payload) => async () => {
  try {
    const response = await API.put(
      `/api/vehicle/residualValueApproval`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const saveFavoriteReports = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      "/api/Vehicle/dynamicreports/savefavourite",
      payload
    );
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

export const saveFavoriteReportsRole = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      "/api/Vehicle/dynamicreports/savefavouriterole",
      payload
    );
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

export const getUsersIdByReportId = (reportId) => async () => {
  try {
    const response = await API.get(
      `/api/Vehicle/dynamicreports/getusersbyreportid?dynamicReportId=${reportId}`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const getRolesIdByReportId = (reportId) => async () => {
  try {
    const response = await API.get(
      `/api/Vehicle/dynamicreports/getrolesbyreportid?dynamicReportId=${reportId}`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const getFavReportsByRole = (key, roleId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/Vehicle/dynamicreports/getfavouritereportsbyuserid?RoleId=${
        roleId ? roleId : 0
      }`,
      {
        params: key,
      }
    );
    dispatch({ type: GET_FAV_REPORTS, payload: response.data });
    dispatch(hideLoader());
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const getReportsById = (reportId) => async () => {
  try {
    const response = await API.get(
      `/api/Vehicle/dynamicreports/getdetailbyreportid?dynamicReportId=${reportId}`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const getColorVariantByTrimId = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/Vehicle/colorvariantsbytrimid?trimId=${payload.trimId}&languageId=${payload.languageId}`
    );
    dispatch(hideLoader());
    dispatch({
      type: GET_COLOR_VARIANT,
      payload: response.data,
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const getColorVariantByColorVariantId =
  (colorVariantId) => async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.get(
        `/api/Vehicle/colorvariantbyid?colorVariantId=${colorVariantId}`
      );
      dispatch(hideLoader());
      dispatch({
        type: GET_COLOR_VARIANT_BY_ID,
        payload: response.data,
      });
    } catch (error) {
      return error;
    }
  };

export const addColorVarant = (payload, history) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(`/api/Vehicle/upsertcolorvariant`, payload);
    dispatch(hideLoader());
    if (response.data.IsSuccess) {
      toast.success(i18next.t("DATA_SAVED_SUCCESSFULLY"));
    } else {
      if (
        response.data.MessageCode ===
        "TRIM_COLOR_VARIANT_COMBINATION_ALREADY_EXISTS"
      ) {
        toast.warning(
          i18next.t("TRIM_COLOR_VARIANT_COMBINATION_ALREADY_EXISTS")
        );
      } else {
        toast.error(i18next.t("ERROR_SAVE_DATA"));
      }
    }
    history.goBack();
  } catch (error) {
    return error;
  }
};

export const getTrimById = (trimId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(`/api/Vehicle/trimbyid?trimId=${trimId}`);
    dispatch(hideLoader());
    dispatch({
      type: GET_TRIM_BY_ID,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};
export const getReportTypes = () => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/dynamicreports/getallreporttypes`
    );
    dispatch({ type: GET_ALL_REPORT_TYPES, payload: response.data });
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const addTrim = (payload, history) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(`/api/Vehicle/upserttrim`, payload);
    dispatch(hideLoader());
    if (response.data.IsSuccess) {
      toast.success(i18next.t("DATA_SAVED_SUCCESSFULLY"));
    } else {
      if (
        response.data.MessageCode === "MAKEMODELTRIM_COMBINATION_ALREADY_EXISTS"
      ) {
        toast.warning(i18next.t("MAKEMODELTRIM_COMBINATION_ALREADY_EXISTS"));
      } else {
        toast.error(i18next.t("ERROR_SAVE_DATA"));
      }
    }
    history.goBack();
  } catch (error) {
    return error;
  }
};

export const setColorVariantData = () => (dispatch) => {
  dispatch({
    type: GET_COLOR_VARIANT,
    payload: [],
  });
};

export const clearTrimData = () => (dispatch) => {
  dispatch({
    type: GET_TRIM_BY_ID,
    payload: [],
  });
};

export const getVehicleFeatures = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `api/vehicleprofile/features?languageId=${payload.languageId}&id=${payload.id}&type=${payload.type}`
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getVehicleProfileById = (id, languageId) => async (dispatch) => {
  try {
    const response = await API.get(
      `api/vehicleprofile/get/${id}?languageId=${languageId}`
    );
    dispatch({
      type: GET_VEHICLE_PROFILE_BY_ID,
      payload: response.data,
    });
  } catch (error) {
    return error;
  }
};

export const clearVehicleProfileData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_VEHICLE_PROFILE_DATA,
  });
};

export const saveVehicleFeatures = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(`api/vehicleprofile/feature`, payload);
    dispatch(hideLoader());
    if (response.data) {
      toast.success(i18next.t("DATA_SAVED_SUCCESSFULLY"));
    } else {
      toast.error(i18next.t("ERROR_SAVE_DATA"));
    }
  } catch (error) {
    return error;
  }
};
export const calculateEMI = (price) => async () => {
  try {
    const response = await API.get(`api/vehicle/calculateemi?price=${price}`);
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};
export const getVehicleSpecs = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `api/vehicleprofile/specifications?languageId=${payload.languageId}&id=${payload.id}&type=${payload.type}`
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const saveVehicleSpecifications =
  ({ languageId, type, ids, specifications }) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.post(`api/vehicleprofile/specification`, {
        type,
        ids,
        specifications,
      });
      dispatch(hideLoader());
      if (response.data) {
        if (ids && ids[0]) {
          const id = ids[0];
          dispatch(getVehicleSpecs({ languageId, type, id }));
        }
        toast.success(i18next.t("DATA_SAVED_SUCCESSFULLY"));
      } else {
        toast.error(i18next.t("ERROR_SAVE_DATA"));
      }
      return Promise.resolve(response);
    } catch (error) {
      return error;
    }
  };

export const uploadDocumentArtifactForVehicleListings = (data) => async () => {
  try {
    const response = await API.post(
      "api/Vehicle/bulkassigndocumentartifact",
      data
    );

    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
  }
};

export const setVehiclesFilterData = (data) => ({
  type: SET_DEALER_VEHICLES_FILTER,
  payload: data,
});

export const getDealerVehicles =
  ({ selectedLanguage, data, dealerId, GridPageSize, pageNumber }) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.get(`/api/DMS/dealervehicles`, {
        params: data
          ? {
              languageId: selectedLanguage,
              dealerId: dealerId,
              vehicleMakeId: data.vehicleMakeId ? data.vehicleMakeId : null,
              vehicleModelId: data.vehicleModelId ? data.vehicleModelId : null,
              specId: data.specId ? data.specId : null,
              bulkUploadDate: data.bulkCreatedDate
                ? data.bulkCreatedDate
                : null,
              pageSize: GridPageSize,
              pageNumber: pageNumber,
            }
          : {
              languageId: selectedLanguage,
              dealerId: dealerId,
              pageSize: GridPageSize,
              pageNumber: pageNumber,
            },
      });
      dispatch(hideLoader());
      dispatch({ type: GET_DEALER_VEHICLES_INFO, payload: response.data });
    } catch (error) {
      dispatch(hideLoader());
      return error;
    }
  };

export const setDealsFilterPayload = (data) => ({
  type: SET_DEALS_FILTER_PAYLOAD,
  payload: data,
});

export const getDealsData =
  ({ selectedLanguage, GridPageSize, pageNumber, data }) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.get(`/api/vehicle/getvehicledeals`, {
        params: data
          ? {
              languageId: selectedLanguage,
              pageSize: GridPageSize,
              pageNumber: pageNumber,
              dealNumber: data.dealNumber ? data.dealNumber : null,
              buyerOrderNumber: data.buyerOrderNumber
                ? data.buyerOrderNumber
                : null,
              startDate: data.dealsCreatedDate.min
                ? data.dealsCreatedDate.min
                : null,
              endDate: data.dealsCreatedDate.max
                ? data.dealsCreatedDate.max
                : null,
              dealerId: data.dealerId ? data.dealerId : null,
              phoneNumber: data.phoneNumber
                ? data.phoneNumber.replace("+966", "")
                : null,
              emailAddress: data.emailAddress ? data.emailAddress : null,
              MakeCode: data.makeId ? data.makeId : null,
              ModelCode: data.modelId ? data.modelId : null,
              TrimCode: data.trimId ? data.trimId : null,
              VehicleDealStatusId: data.dealStatusId ? data.dealStatusId : null,
            }
          : {
              languageId: selectedLanguage,
              pageSize: GridPageSize,
              pageNumber: pageNumber,
            },
      });
      dispatch(hideLoader());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(hideLoader());
      return error;
    }
  };

export const getDealById = (vehicleDealId, languageId) => async () => {
  try {
    const response = await API.get(
      `api/vehicle/vehicledealbyid?vehicleDealId=${vehicleDealId}&languageId=${languageId}`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const getDealStatus = (languageId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/vehicle/dealstatus?languageId=${languageId}`
    );
    dispatch(hideLoader());
    dispatch({ type: GET_DEAL_STATUS, payload: response.data });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const updateDealStatus = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(`/api/vehicle/deal`, data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (err) {
    dispatch(hideLoader());
    return err;
  }
};

export const downloadDealDocument = (artifactId) => async () => {
  try {
    const response = await API.get(
      `/api/vehicleprofile/downloaddealdocument?vehicleDealArtifactId=${artifactId}`,
      {
        responseType: "blob",
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getDocumentExternal = (params) => async () => {
  try {
    const response = await API.get(`/api/Document/getdocument`, {
      params,
      responseType: "blob",
    });
    return Promise.resolve(response);
  } catch (error) {
    toast.error(i18next.t("FRM_ERR_MSG_SOMETHING_WRONG"));
    return error;
  }
};

export const getFileData = async (url) => {
  const response = await axios.get(url, { responseType: "blob" });
  return await response.data;
};

export const getImportedByData = (languageId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/Vehicle/importers?languageId=${languageId}`
    );
    dispatch(hideLoader());
    dispatch({ type: GET_IMPORTED_BY_DATA, payload: response.data });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getFulfilledByData = (languageId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/Vehicle/fulfilledby?languageId=${languageId}`
    );
    dispatch(hideLoader());
    dispatch({ type: GET_FULFILLED_BY_DATA, payload: response.data });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const sendSurveyLink = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/deal/sendsurveylink", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const downloadDeliveryNote = (dealNumber) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `api/Invoice/GenerateDeliveryNoteDocument?dealNumber=${dealNumber}`,
      {
        responseType: "blob",
      }
    );

    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (err) {
    dispatch(hideLoader());
    return err;
  }
};

export const extendBookingPeriod = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/Deal/extendbookingperiod", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const updateCommunicationColor = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      `/api/VehicleProfile/setcommunicationvariant`,
      payload
    );
    dispatch(hideLoader());
    if (response.data) {
      toast.success(i18next.t("DATA_UPDATE_SUCCESS"));
    } else {
      toast.error(i18next.t("ERROR_SAVE_DATA"));
    }
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
  }
};

export const getImporters = (languageId) => async () => {
  try {
    const response = await API.get(
      `/api/Vehicle/importers?languageId=${languageId}`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const getFulfillmentpartners = (languageId) => async () => {
  try {
    const response = await API.get(
      `/api/Vehicle/fulfilledby?languageId=${languageId}`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const getSuppliers = (languageId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/vehicle/suppliers?languageId=${languageId}`
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getSupplierById = (supplierId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/vehicle/supplierbyid?supplierId=${supplierId}`
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const saveSupplier = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/vehicle/upsertsupplier", payload);
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getVehiclePolicyByDealId = (vehicleDealId) => async () => {
  try {
    const response = await API.get(
      `api/Vehicle/policy?vehicleDealId=${vehicleDealId}`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const getBuyerDocuments =
  (languageId, vehicleId) => async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.get(
        `/api/Vehicle/buyerdocuments?languageId=${languageId}&vehicleListingId=${vehicleId}`
      );
      dispatch(hideLoader());
      return Promise.resolve(response);
    } catch (error) {
      dispatch(hideLoader());
      return error;
    }
  };

export const setWishlistLeadsFilter = (data) => ({
  type: SET_WISHLIST_LEADS_FILTER,
  payload: data,
});

export const getLeadsFromWishlist =
  ({ selectedLanguage, GridPageSize, pageNumber, data }) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.get(`/api/Vehicle/leadsfromwishlist`, {
        params: data
          ? {
              LanguageId: selectedLanguage,
              PageSize: GridPageSize,
              PageNumber: pageNumber,
              ModelYearId: data.vehicleModelYear ? data.vehicleModelYear : null,
              MakeId: data.makeId ? data.makeId : null,
              ModelId: data.modelId ? data.modelId : null,
              TrimId: data.trimId ? data.trimId : null,
              ColorVariantId: data.colorVariantId ? data.colorVariantId : null,
              CityId: data.city ? data.city : null,
              DealerId: data.dealerId ? data.dealerId : null,
              DistributorId: data.supplierId ? data.supplierId : null,
              Email: data.emailAddress ? data.emailAddress : null,
              StartDate: data.leadsCreatedDate.min
                ? data.leadsCreatedDate.min
                : null,
              EndDate: data.leadsCreatedDate.max
                ? data.leadsCreatedDate.max
                : null,
            }
          : {
              LanguageId: selectedLanguage,
              PageSize: GridPageSize,
              PageNumber: pageNumber,
            },
      });
      dispatch(hideLoader());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(hideLoader());
      return error;
    }
  };

export const getListingStatus = (id) => async (dispatch) => {
  try {
    const response = await API.get(
      `api/vehicle/vehiclelistingstatus?languageId=${id}`
    );

    dispatch({
      type: GET_VEHICLE_LISTING_STATUS,
      payload: response.data,
    });
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};
export const updateBalloonPercentage = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      `/api/VehicleProfile/emipercentage`,
      payload
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const setLeadsFilterDetails = (data) => ({
  type: SET_LEADS_FILTER_DETAILS,
  payload: data,
});

export const getVehicleLeadsDetails =
  ({ selectedLanguage, GridPageSize, pageNumber, data }) =>
  async (dispatch) => {
    try {
      dispatch(showLoader());
      const response = await API.get(`/api/Lead/getvehicleleads`, {
        params: data
          ? {
              LanguageId: selectedLanguage,
              PageSize: GridPageSize,
              PageNumber: pageNumber,
              ModelYearId: data.vehicleModelYear ? data.vehicleModelYear : null,
              MakeId: data.makeId ? data.makeId : null,
              ModelId: data.modelId ? data.modelId : null,
              TrimId: data.trimId ? data.trimId : null,
              CityId: data.city ? data.city : null,
              RegionId: data.regionId ? data.regionId : null,
              CountryId: data.countryId ? data.countryId : null,
              IsFinance: data.IsFinance ? data.IsFinance : null,
              BuyerName: data.buyerName ? data.buyerName : null,
              BuyerContact: data.buyerContact ? data.buyerContact : null,
              PurchasePlanDurationKey: data.purchasePlanDuration
                ? data.purchasePlanDuration
                : null,
              StartDate: data.leadsCreatedDate.min
                ? data.leadsCreatedDate.min
                : null,
              EndDate: data.leadsCreatedDate.max
                ? data.leadsCreatedDate.max
                : null,
            }
          : {
              LanguageId: selectedLanguage,
              PageSize: GridPageSize,
              PageNumber: pageNumber,
            },
      });
      dispatch(hideLoader());
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(hideLoader());
      return error;
    }
  };
export const getPurchasePlanDuration = (lan) => {
  return () => {
    return API.get("/api/Lead/getPurchasePlanDuration", {
      params: { languageId: lan },
    });
  };
};

export const assignDealToSaleExecutive = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put("/api/deal/salesexecutiveassignment", data);
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const assignDealToCustomerExecutive = (data) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      "/api/deal/customercareexecutiveassignment",
      data
    );
    dispatch(hideLoader());
    return Promise.resolve(response);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const updateVehicleProfile = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.put(
      `/api/VehicleProfile/updatevehicleprofile`,
      payload
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getCountriesOfOrigin = (id) => async (dispatch) => {
  try {
    const response = await API.get(
      `api/vehicle/countriesoforigin?languageId=${id}`
    );

    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const getBodyStyle = (id) => async (dispatch) => {
  try {
    const response = await API.get(`/api/Vehicle/bodystyles?languageId=${id}`);

    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const upsertBodyStyle = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(`api/vehicle/upsertbodystyle`, payload);
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const getImporterById = (importerId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/vehicle/importerbyid?importerId=${importerId}`
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    return error;
  }
};

export const saveImporter = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post("/api/vehicle/upsertimporter", payload);
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};
export const getAllImporters = (languageId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `/api/vehicle/importers?languageId=${languageId}`
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getBodyStyleById = (bodyStyleId) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.get(
      `api/vehicle/bodystylebyid?bodyStyleId=${bodyStyleId}`
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return error;
  }
};

export const getSpecification = (id) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/specificationcategory?languageId=${id}`
    );

    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const getSpecificationCategoryById = (id) => async (dispatch) => {
  try {
    const response = await API.get(
      `/api/Vehicle/specificationcategorybyid?specificationCategoryId=${id}`
    );

    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};

export const upsertSpecificationData = (payload) => async (dispatch) => {
  try {
    dispatch(showLoader());
    const response = await API.post(
      `api/vehicle/upsertspecificationcategory`,
      payload
    );
    dispatch(hideLoader());
    return Promise.resolve(response.data);
  } catch (error) {
    dispatch(hideLoader());
    return Promise.reject(error);
  }
};
