import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackIcon } from "../../components/gogo-icons";
import VehicleSpecificationList from "../../components/vehicle-specification-list";
import { useDispatch, useSelector } from "react-redux";
import {
  clearVehicleProfileData,
  getVehicleProfileById,
} from "../../redux/actions/vehicleAction";

const VehicleProfileSpecification = ({
  vehicleProfileId,
  handleSave,
  specifications,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const languageId = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );
  const vehicleProfile = useSelector(
    (state) => state.vehicleReducer.vehicleProfile
  );

  useEffect(() => {
    dispatch(getVehicleProfileById(vehicleProfileId, languageId));
    return () => {
      dispatch(clearVehicleProfileData());
    };
  }, [dispatch, vehicleProfileId, languageId]);

  const saveData = (selectedSpecs) => {
    handleSave(selectedSpecs);
  };

  return (
    <div className="mt-1">
      <div className="container mx-auto">
        <div
          className="flex flex-row gap-2 items-center cursor-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          <BackIcon className="back-button arabic-back-icon" />
          <span className="page-header">{t("EDIT_VEHICLE_PROFILE_SPECS")}</span>
        </div>
        <div className="bg-white my-4 px-8 py-6">
          {vehicleProfile && (
            <p className="page-header">
              {vehicleProfile?.Year +
                " - " +
                vehicleProfile?.Make +
                " - " +
                vehicleProfile?.Model +
                `${vehicleProfile?.Trim ? ` - ${vehicleProfile?.Trim}` : ""}` +
                `${
                  vehicleProfile?.ColorVariant
                    ? ` - ${vehicleProfile?.ColorVariant}`
                    : ""
                }`}
            </p>
          )}
          <VehicleSpecificationList
            specifications={specifications}
            saveData={saveData}
          />
        </div>
      </div>
    </div>
  );
};
export default VehicleProfileSpecification;
