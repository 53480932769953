import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FeatureTypes } from "../utilities/constants/global.constants";
import VehicleProfileSpecification from "./vehicle-profile/VehicleProfileSpecification";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getVehicleSpecs,
  saveVehicleSpecifications,
} from "../redux/actions/vehicleAction";
import ModelSpecifications from "./manage/ModelSpecifications";
import VariantSpecifications from "./manage/VariantSpecifications";

const Specifications = ({ type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const id = params.id;
  const [specifications, setSpecifications] = useState([]);

  const languageId = useSelector(
    (state) => state.globalReducer.selectedLanguage
  );

  useEffect(() => {
    const payload = {
      languageId,
      id,
      type,
    };
    dispatch(getVehicleSpecs(payload)).then((res) => {
      if (res) {
        setSpecifications(res.Specifications);
      }
    });
  }, [dispatch, id, languageId, type]);

  const handleSave = (selectedSpecs) => {
    const payload = {
      ids: [id],
      specifications: selectedSpecs,
      type,
      languageId,
    };
    dispatch(saveVehicleSpecifications(payload)).then((res) => {
      if (res) {
        setSpecifications([]);
        history.goBack();
      }
    });
  };

  if (type === FeatureTypes.VehicleProfile) {
    return (
      <VehicleProfileSpecification
        vehicleProfileId={id}
        handleSave={handleSave}
        specifications={specifications}
      />
    );
  }

  if (type === FeatureTypes.Model) {
    return (
      <ModelSpecifications
        modelId={id}
        handleSave={handleSave}
        specifications={specifications}
      />
    );
  }

  if (type === FeatureTypes.Trim) {
    return (
      <VariantSpecifications
        trimId={id}
        handleSave={handleSave}
        specifications={specifications}
      />
    );
  }

  return <></>;
};
export default Specifications;
