import { lazy } from "react";
import {
  ApplicationPrivileges,
  ApplicationRoles,
} from "../utilities/constants/role.constants";
import { FeatureTypes } from "../utilities/constants/global.constants";
import Features from "../views/Features";
import Specification from "../views/Specifications";
import Documents from "../views/Documents";

const routes = [
  {
    path: "/",
    exact: true,
    redirectTo: "/dashboard",
  },
  {
    path: "/dashboard",
    component: lazy(() => import("../views/dashboard/Dashboard")),
    exact: true,
  },
  {
    path: "/warranty-request",
    component: lazy(() => import("../views/InspectionListingPage")),
    exact: true,
    roles: [ApplicationRoles.WAYPOINT_INSPECTOR],
  },
  {
    path: "/vehicles",
    component: lazy(() => import("../views/my-vehicle/MyVehicle")),
    exact: true,
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.QC_ANALYST,
      ApplicationRoles.DMS_ADMIN,
      ApplicationRoles.DMS_SALESPERSON,
      ApplicationRoles.INSPECTOR,
      ApplicationRoles.RVA_ANALYST,
      ApplicationRoles.SALES_ADMIN,
    ],
    privileges: [ApplicationPrivileges.VehicleView],
  },
  {
    path: "/vehicles/details/:id",
    component: lazy(() =>
      import("../views/vehicle/vehicle-details/VehicleDetailsWrapper")
    ),
    exact: true,
    roles: [
      ApplicationRoles.DMS_ADMIN,
      ApplicationRoles.DMS_SALESPERSON,
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.QC_ANALYST,
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.INSPECTOR,
      ApplicationRoles.SALES_ADMIN,
      ApplicationRoles.WAYPOINT_INSPECTOR,
    ],
    privileges: [ApplicationPrivileges.VehicleDetailsView],
  },
  {
    path: "/vehicles/approval/:id",
    component: lazy(() =>
      import("../views/vehicle/qc-vehicle-details/VehicleDetails")
    ),
    exact: true,
    roles: [ApplicationRoles.QC_ADMIN, ApplicationRoles.QC_ANALYST],
    privileges: [ApplicationPrivileges.VehicleApproveRejectView],
  },
  {
    path: "/vehicles/history/:id",
    component: lazy(() => import("../views/vehicle/History")),
    exact: true,
    roles: [
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.QC_ANALYST,
      ApplicationRoles.DMS_ADMIN,
      ApplicationRoles.DMS_SALESPERSON,
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.SALES_ADMIN,
    ],
    privileges: [ApplicationPrivileges.VehicleHistoryView],
  },

  {
    path: "/leads/crm",
    component: lazy(() => import("../views/leads/CRMLeads")),
    exact: true,
    roles: [ApplicationRoles.CRM_ADMIN, ApplicationRoles.CRM_SALESPERSON],
    privileges: [ApplicationPrivileges.CRMLeadsView],
  },
  {
    path: "/users",
    component: lazy(() => import("../views/user-management/Users")),
    exact: true,
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.CRM_ADMIN,
      ApplicationRoles.CUSTOMER_CARE_ADMIN,
      ApplicationRoles.SALES_ADMIN,
    ],
    privileges: [ApplicationPrivileges.UsersView],
  },
  {
    path: "/users/create-user",
    component: lazy(() => import("../views/user-management/CreateUser")),
    exact: true,
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.CRM_ADMIN,
      ApplicationRoles.CUSTOMER_CARE_ADMIN,
      ApplicationRoles.SALES_ADMIN,
    ],
    privileges: [ApplicationPrivileges.CreateUsersView],
  },
  {
    path: "/users/update-user/:userId",
    component: lazy(() => import("../views/user-management/CreateUser")),
    exact: true,
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.CRM_ADMIN,
    ],
    privileges: [ApplicationPrivileges.UpdateUsersView],
  },
  {
    path: "/dms/users",
    component: lazy(() => import("../views/user-management/DMSUsers")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
    privileges: [ApplicationPrivileges.DMSUsersView],
  },
  {
    path: "/dms/users/create-user",
    component: lazy(() => import("../views/user-management/CreateDmsUser")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
    privileges: [ApplicationPrivileges.DMSCreateUsersView],
  },
  {
    path: "/dms/users/update-user/:userId",
    component: lazy(() => import("../views/user-management/CreateDmsUser")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
    privileges: [ApplicationPrivileges.DMSUpdateUsersView],
  },
  {
    path: "/profile-change-request",
    component: lazy(() =>
      import("../views/user-management/ProfileChangeRequestList")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.DMS_ADMIN],
    privileges: [ApplicationPrivileges.ProfileChangeRequestView],
  },
  {
    path: "/profile-change-request/:userId",
    component: lazy(() =>
      import("../views/user-management/ProfileChangeDetail")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.DMS_ADMIN],
    privileges: [ApplicationPrivileges.ProfileChangeRequestView],
  },
  {
    path: "/profile",
    component: lazy(() => import("../views/user-management/UserProfile")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN, ApplicationRoles.DMS_SALESPERSON],
    privileges: [ApplicationPrivileges.ChangeProfileView],
  },

  {
    path: "/view-profile",
    component: lazy(() => import("../views/user-management/UserProfile")),
    exact: true,
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.QC_ANALYST,
    ],
    privileges: [ApplicationPrivileges.ViewProfile],
  },
  {
    path: "/bulk-upload",
    component: lazy(() => import("../views/bulk-upload/UploadVehicle")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
    privileges: [],
  },
  {
    path: "/upload-vin",
    component: lazy(() => import("../views/bulk-upload/UploadVin")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.SoldVehicle],
  },
  {
    path: "/bulk-upload-report",
    component: lazy(() => import("../views/bulk-upload/BulkUploadReport")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
    privileges: [],
  },
  {
    path: "/bulk-upload-report/:id",
    component: lazy(() => import("../views/bulk-upload/BulkReportById")),
  },

  {
    path: "/configuration",
    component: lazy(() => import("../views/configuration/ConfigurationView")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/packages/edit/:id",
    component: lazy(() => import("../views/configuration/Packages")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/services/:id",
    component: lazy(() =>
      import("../views/configuration/Services/B2CServiceDetailsForm")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/services-configuration/:serviceId",
    component: lazy(() =>
      import("../views/configuration/Services/B2CServiceConfigurationDetails")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/promotion-details",
    component: lazy(() =>
      import("../views/configuration/Promotion/PromotionDetails")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/promotion-details/edit/:promotionId",
    component: lazy(() =>
      import("../views/configuration/Promotion/PromotionDetails")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/services-configuration/edit/:serviceId/:serviceConfigId",
    component: lazy(() =>
      import("../views/configuration/Services/B2CServiceConfigurationDetails")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/crm",
    component: lazy(() => import("../views/po-admin/CRM")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.CRMView],
  },
  {
    path: "/dms",
    component: lazy(() => import("../views/dms/DMSApplicationList")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.DMSApplicationView],
  },
  {
    path: "/dms/package/:id",
    component: lazy(() => import("../views/packages/CurrentPackages")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.DMSApplicationView],
  },
  {
    path: "/invoices",
    component: lazy(() => import("../views/invoices/Invoice")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/charge-details/:id",
    component: lazy(() => import("../views/invoices/ChargeDetails")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/dms-application/view",
    component: lazy(() => import("../views/dms/DealerView")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.DMSApplicationView],
  },
  {
    path: "/feedback",
    component: lazy(() => import("../views/po-admin/Feedback/Feedback")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.FeedbackView],
  },
  {
    path: "/user-feedback",
    component: lazy(() => import("../views/UserFeedback")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN, ApplicationRoles.DMS_SALESPERSON],
    privileges: [],
  },

  {
    path: "/feedback-details",
    component: lazy(() => import("../views/po-admin/Feedback/FeedbackDetails")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.FeedbackView],
  },
  {
    path: "/configuration/inspection-fees",
    component: lazy(() => import("../views/configuration/InspectionFees")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.InspectionFeesView],
  },
  {
    path: "/configuration/vehicle-delisting",
    component: lazy(() => import("../views/configuration/VehicleDeListing")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.VehicleDeListingView],
  },
  {
    path: "/configuration/top-cities",
    component: lazy(() => import("../views/configuration/TopCities")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.TopCitiesView],
  },
  {
    path: "/configuration/top-brands",
    component: lazy(() => import("../views/configuration/TopBrands")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.TopBrandsView],
  },
  {
    path: "/configuration/pricing-configuration",
    component: lazy(() =>
      import("../views/configuration/PricingConfiguration")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.TopBrandsView],
  },
  {
    path: "/configuration/email-configuration",
    component: lazy(() => import("../views/configuration/EmailConfiguration")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.TopBrandsView],
  },
  {
    path: "/configuration/emi-configuration",
    component: lazy(() => import("../views/configuration/EMIConfiguration")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.TopBrandsView],
  },
  {
    path: "/configuration/emi-range-configuration",
    component: lazy(() =>
      import("../views/configuration/EMIRangeConfiguration")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.TopBrandsView],
  },
  {
    path: "/configuration/packages",
    component: lazy(() => import("../views/configuration/AddPackages")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.AddPackageView],
  },
  {
    path: "/configuration/vehicle-acceptance-criteria",
    component: lazy(() =>
      import("../views/configuration/VehicleAcceptanceCriteria")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.VehicleAcceptanceCriteriaView],
  },
  {
    path: "/dms-salesperson",
    component: lazy(() => import("../views/dms/SalesPerson")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
    privileges: [ApplicationPrivileges.SalesPersonView],
  },
  {
    path: "/packages",
    component: lazy(() => import("../views/packages/PackageView")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
    privileges: [ApplicationPrivileges.PackagesView],
  },
  {
    path: "/cart",
    component: lazy(() => import("../views/shopping-cart/Cart")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
    privileges: [ApplicationPrivileges.PackagesView],
  },
  {
    path: "/success-payment/:params",
    component: lazy(() => import("../views/invoices/PaymentSuccess")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
    privileges: [ApplicationPrivileges.PackagesView],
  },
  {
    path: "/failure-payment",
    component: lazy(() => import("../views/invoices/PaymentFailure")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
    privileges: [ApplicationPrivileges.PackagesView],
  },
  {
    path: "/queries",
    component: lazy(() => import("../views/leads/QueryList")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN, ApplicationRoles.DMS_SALESPERSON],
    privileges: [ApplicationPrivileges.EditPackageView],
  },
  {
    path: "/invoices/details/:id",
    component: lazy(() => import("../views/invoices/InvoiceDetails")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },

  {
    path: "/petromin-auth",
    component: lazy(() => import("../views/public/PetrominAuth")),
    exact: true,
  },
  {
    path: "/petromin-signup",
    component: lazy(() => import("../views/public/PetrominAuth")),
    exact: true,
  },
  {
    path: "/petromin-reset-password",
    component: lazy(() => import("../views/public/PetrominAuth")),
    exact: true,
  },
  {
    path: "/about-us",
    component: lazy(() => import("../views/AboutUs")),
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: lazy(() => import("../views/PrivacyPolicy")),
    exact: true,
  },
  {
    path: "/terms-conditions",
    component: lazy(() => import("../views/TermsConditions")),
    exact: true,
  },
  {
    path: "/bae28f3c-bace-42b3-b2cb-4e97f32561d1",
    component: lazy(() => import("../views/FindVin")),
    exact: true,
  },
  {
    path: "/notifications",
    component: lazy(() => import("../components/notification/Notification")),
    exact: true,
  },
  {
    path: "/package-list/:id",
    component: lazy(() => import("../../src/views/packages/PackageList")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/appointments",
    component: lazy(() => import("../views/Appointments/Appointment")),
    exact: true,
    roles: [ApplicationRoles.CUSTOMER_CARE_ADMIN],
  },
  // {
  //   path: "/configuration/b2c-packages",
  //   component: lazy(() =>
  //     import("../views/configuration/B2CPackage/B2CPackageDetails")
  //   ),
  //   exact: true,
  //   roles: [ApplicationRoles.PETROMIN_ADMIN],
  //   privileges: [ApplicationPrivileges.AddPackageView],
  // },
  {
    path: "/packageConfiguration",
    component: lazy(() =>
      import("../views/configuration/B2CPackage/B2CPackageConfigurationDetails")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },

  {
    path: "/configuration/promotion-usage/:id",
    component: lazy(() =>
      import("../views/configuration/Promotion/PromotionUsage")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },

  {
    path: "/configuration/b2c-services",
    component: lazy(() =>
      import("../views/configuration/Services/B2CServiceDetailsForm")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.AddPackageView],
  },
  {
    path: "/configuration/b2c-services/:id",
    component: lazy(() =>
      import("../views/configuration/Services/B2CServiceDetailsForm")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.AddPackageView],
  },
  {
    path: "/configuration/b2c-packages",
    component: lazy(() =>
      import("../views/configuration/B2CPackage/B2CPackageForm")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.AddPackageView],
  },
  {
    path: "/configuration/b2c-packages/:id",
    component: lazy(() =>
      import("../views/configuration/B2CPackage/B2CPackageForm")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.AddPackageView],
  },
  {
    path: "/configuration/b2c-packages/edit/:id",
    component: lazy(() =>
      import("../views/configuration/B2CPackage/B2CPackageForm")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.AddPackageView],
  },
  {
    path: "/configuration/associate-services",
    component: lazy(() => import("../views/configuration/B2CPackage/Services")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.AddPackageView],
  },
  {
    path: "/configuration/all-promotions",
    component: lazy(() =>
      import("../views/configuration/Promotion/PromotionUsage")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/vehicle-wizard/:id",
    component: lazy(() =>
      import("../../src/views/qc-vehicle-wizard/VehicleWizard")
    ),
    exact: true,
    roles: [ApplicationRoles.INSPECTOR],
  },
  {
    path: "/makemodatrim",
    component: lazy(() => import("../views/makemodaltrim/MakeModalTrimView")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/quotations",
    component: lazy(() => import("../views/quotations/Quotation")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/configuration/bannerImage",
    component: lazy(() => import("../views/configuration/CMSConfiguration")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/configuration/verbose-configuration",
    component: lazy(() =>
      import("../views/configuration/CMSTextConfiguration")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/quotations/:id",
    component: lazy(() => import("../views/quotations/QuotationById")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/vehicle-profile",
    component: lazy(() => import("../views/vehicle-profile/VehicleProfile")),
    exact: true,
    privileges: [ApplicationPrivileges.ViewVehicleProfile],
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.DMS_ADMIN,
    ],
  },
  {
    path: "/vehicle-profile/:id",
    component: lazy(() =>
      import("../views/vehicle-profile/VehicleProfileDetails")
    ),
    exact: true,
    privileges: [ApplicationPrivileges.ViewVehicleProfile],
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.DMS_ADMIN,
    ],
  },
  {
    path: "/variant/features/:id",
    component: () => <Features type={FeatureTypes.Trim} />,
    exact: true,
    privileges: [ApplicationPrivileges.ViewVehicleProfile],
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.QC_ADMIN],
  },
  {
    path: "/model/features/:id",
    component: () => <Features type={FeatureTypes.Model} />,
    exact: true,
    privileges: [ApplicationPrivileges.ViewVehicleProfile],
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.QC_ADMIN],
  },
  {
    path: "/vehicle-profile/features/:id",
    component: () => <Features type={FeatureTypes.VehicleProfile} />,
    exact: true,
    privileges: [ApplicationPrivileges.ViewVehicleProfile],
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.DMS_ADMIN,
    ],
  },
  {
    path: "/variant/specifications/:id",
    component: () => <Specification type={FeatureTypes.Trim} />,
    exact: true,
    privileges: [ApplicationPrivileges.ViewVehicleProfile],
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.QC_ADMIN],
  },
  {
    path: "/model/specifications/:id",
    component: () => <Specification type={FeatureTypes.Model} />,
    exact: true,
    privileges: [ApplicationPrivileges.ViewVehicleProfile],
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.QC_ADMIN],
  },
  {
    path: "/vehicle-profile/specifications/:id",
    component: () => <Specification type={FeatureTypes.VehicleProfile} />,
    exact: true,
    privileges: [ApplicationPrivileges.ViewVehicleProfile],
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.DMS_ADMIN,
    ],
  },
  {
    path: "/vehicle-profile/documents/:id",
    component: () => <Documents type={FeatureTypes.VehicleProfile} />,
    exact: true,
    privileges: [ApplicationPrivileges.ViewVehicleProfile],
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.QC_ADMIN,
      ApplicationRoles.DMS_ADMIN,
    ],
  },
  {
    path: "/configuration/addQuotation",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddQuotationStatus")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/addQuotation/:id",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddQuotationStatus")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/addQCRejection",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddQCRejectionStatus")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/addQCRejection/:id",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddQCRejectionStatus")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/addLocationName",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddLocationName")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/addLocationName/:id",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddLocationName")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/system-configuration",
    component: lazy(() => import("../views/configuration/SystemConfiguration")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/configuration/editTemplate/:id",
    component: lazy(() =>
      import("../views/configuration/template-configuration/EditEmailTemplate")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/addLocationName",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddLocationName")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/editCommonTemplate",
    component: lazy(() =>
      import("../views/configuration/template-configuration/EditCommonTemplate")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/editSMSNotification/:id",
    component: lazy(() =>
      import(
        "../views/configuration/template-configuration/EditSMSNotification"
      )
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/editWhatsappNotification/:id",
    component: lazy(() =>
      import(
        "../views/configuration/template-configuration/EditWhatsappNotification"
      )
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },

  {
    path: "/configuration/editAlertNotification/:id",
    component: lazy(() =>
      import(
        "../views/configuration/template-configuration/EditAlertNotification"
      )
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/editMobileNotification/:id",
    component: lazy(() =>
      import(
        "../views/configuration/template-configuration/EditMobileNotification"
      )
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/addLocationName/:id",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddLocationName")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/workingsector",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/SaveWorkingSector")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/workingsector/:id",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/SaveWorkingSector")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/bank-details",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/SaveBankDetails")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/bank-details/:id",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/SaveBankDetails")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/edit-dealer/:userId",
    component: lazy(() => import("../views/dms/EditDealer")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.DMSApplicationView],
  },
  {
    path: "/push-notification",
    component: lazy(() => import("../views/PushNotification")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.DMSApplicationView],
  },
  {
    path: "/configuration/addFaqCategory",
    component: lazy(() =>
      import("../views/configuration/template-configuration/EditFAQCategory")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/editFaqCategory/:id",
    component: lazy(() =>
      import("../views/configuration/template-configuration/EditFAQCategory")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/addfaq",
    component: lazy(() =>
      import("../views/configuration/template-configuration/EditFAQTemplate")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/editfaq/:id",
    component: lazy(() =>
      import("../views/configuration/template-configuration/EditFAQTemplate")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/reports",
    component: lazy(() => import("../views/dynamic-reports/Reports")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/reports/addDynamicReport",
    component: lazy(() => import("../views/dynamic-reports/AddDynamicReports")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/reports/addDynamicReport/:id",
    component: lazy(() => import("../views/dynamic-reports/AddDynamicReports")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/reports/viewReport/:id",
    component: lazy(() => import("../views/dynamic-reports/ViewReport")),
    exact: true,
  },
  {
    path: "/reports/favuser/:id",
    component: lazy(() => import("../views/dynamic-reports/FavUserReport")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/reports/favrole/:id",
    component: lazy(() => import("../views/dynamic-reports/FavRoleReport")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/translations",
    component: lazy(() => import("../views/Translator")),
    exact: true,
    roles: [ApplicationRoles.TRANSLATOR],
    privileges: [ApplicationPrivileges.ViewTranslations],
  },
  {
    path: "/warranty",
    component: lazy(() => import("../views/Warranty")),
    exact: true,
    roles: [ApplicationRoles.WARRANTY],
    privileges: [ApplicationPrivileges.ViewWarranties],
  },
  {
    path: "/configuration/emi-calculator-url",
    component: lazy(() => import("../views/configuration/EMICalculatorURL")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },

  {
    path: "/color",
    component: lazy(() => import("../views/manage/ManageColor")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },

  {
    path: "/add-color",
    component: lazy(() => import("../views/manage/ColorDetailsView")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/edit-color",
    component: lazy(() => import("../views/manage/ColorDetailsView")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/variant",
    component: lazy(() => import("../views/manage/ManageVariant")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/add",
    component: lazy(() => import("../views/manage/VariantDetailsView")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/edit",
    component: lazy(() => import("../views/manage/VariantDetailsView")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/make",
    component: lazy(() => import("../views/manage/ManageMake")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.QC_ADMIN],
  },
  {
    path: "/model",
    component: lazy(() => import("../views/manage/ManageModel")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.QC_ADMIN],
  },
  {
    path: "/add-make",
    component: lazy(() => import("../views/manage/MakeDetailsView")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.QC_ADMIN],
  },
  {
    path: "/add-make/:id",
    component: lazy(() => import("../views/manage/MakeDetailsView")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.QC_ADMIN],
  },
  {
    path: "/add-model",
    component: lazy(() => import("../views/manage/ModelDetailsView")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.QC_ADMIN],
  },
  {
    path: "/add-model/:id",
    component: lazy(() => import("../views/manage/ModelDetailsView")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.QC_ADMIN],
  },
  {
    path: "/configuration/add-role",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddUserRole")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/add-role/:id",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddUserRole")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/view-headers/:id",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/ViewHeaders")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/import-translations",
    component: lazy(() =>
      import("../views/configuration/template-configuration/ImportTranslations")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN, ApplicationRoles.TRANSLATOR],
    privileges: [ApplicationPrivileges.ViewTranslations],
  },
  {
    path: "/model-documents/:id",
    component: lazy(() => import("../views/manage/ModelDocument")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/variant-documents/:id",
    component: lazy(() => import("../views/manage/VariantDocument")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/deals",
    component: lazy(() => import("../views/deals")),
    exact: true,
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.CUSTOMER_CARE_ADMIN,
      ApplicationRoles.SALES_ADMIN,
      ApplicationRoles.FINPAL_EXECUTIVE,
    ],
    privileges: [ApplicationPrivileges.ViewDeals],
  },
  {
    path: "/deals/:id",
    component: lazy(() => import("../views/deals/DealById")),
    exact: true,
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.CUSTOMER_CARE_ADMIN,
      ApplicationRoles.SALES_ADMIN,
      ApplicationRoles.FINPAL_EXECUTIVE,
    ],
    privileges: [ApplicationPrivileges.ViewDeals],
  },
  {
    path: "/vehicle/bulkassign",
    component: lazy(() => import("../views/bulkassign")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
  },
  {
    path: "/configuration/view-privileges/:id",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/ViewPrivileges")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/dms-application/pending-requests",
    component: lazy(() => import("../views/dms/PendingDealerRequest")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.DMSApplicationView],
  },
  {
    path: "/configuration/add-privilege",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddPrivilege")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/add-privilege/:id",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddPrivilege")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/mobile-force-update",
    component: lazy(() => import("../views/configuration/MobileForceUpdate")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/CustomerFeedbacks",
    component: lazy(() => import("../views/FeedBackEmails/FeedBackEmails")),
    exact: true,
    roles: [ApplicationRoles.CUSTOMER_CARE_ADMIN],
    privileges: [ApplicationPrivileges.CustomerFeedbacks],
  },
  {
    path: "/configuration/mobile-app-down",
    component: lazy(() => import("../views/configuration/MobileAppDown")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/stc-pay",
    component: lazy(() => import("../views/configuration/STCPay")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/vehicles/product-catalogue",
    component: lazy(() =>
      import("../views/product-catalogue/ProductCatalogue")
    ),
    exact: true,
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.DMS_ADMIN,
      ApplicationRoles.QC_ADMIN,
    ],
    privileges: [ApplicationPrivileges.ViewProductCatalogue],
  },
  {
    path: "/vehicles/import-catalogue",
    component: lazy(() => import("../views/product-catalogue/ImportCatalogue")),
    exact: true,
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.DMS_ADMIN,
      ApplicationRoles.QC_ADMIN,
    ],
    privileges: [ApplicationPrivileges.ViewProductCatalogue],
  },
  {
    path: "/wishlist-leads",
    component: lazy(() => import("../views/deals/WishlistLead")),
    exact: true,
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.CUSTOMER_CARE_ADMIN,
      ApplicationRoles.SALES_ADMIN,
      ApplicationRoles.FINPAL_EXECUTIVE,
    ],
  },
  {
    path: "/leads",
    component: lazy(() => import("../views/deals/LeadsDetails")),
    exact: true,
    roles: [
      ApplicationRoles.PETROMIN_ADMIN,
      ApplicationRoles.CUSTOMER_CARE_ADMIN,
      ApplicationRoles.SALES_ADMIN,
      ApplicationRoles.FINPAL_EXECUTIVE,
    ],
  },
  {
    path: "/configuration/add-distributor",
    component: lazy(() =>
      import("../views/configuration/Distributors/SaveDistributor")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ViewSupplier],
  },
  {
    path: "/configuration/edit-distributor/:id",
    component: lazy(() =>
      import("../views/configuration/Distributors/SaveDistributor")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ViewSupplier],
  },
  {
    path: "/vehicles/invoice/:id",
    component: lazy(() => import("../views/invoices/CreateInvoice")),
    exact: true,
    privileges: [ApplicationPrivileges.CreateInvoice],
  },
  {
    path: "/vehicles/quotation/:id",
    component: lazy(() => import("../views/invoices/CreateQuotation")),
    exact: true,
    privileges: [ApplicationPrivileges.CreateQuotation],
  },
  {
    path: "/configuration/add-importer",
    component: lazy(() =>
      import("../views/configuration/Importers/SaveImporter")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ViewSupplier],
  },
  {
    path: "/configuration/edit-importer/:id",
    component: lazy(() =>
      import("../views/configuration/Importers/SaveImporter")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ViewSupplier],
  },
  {
    path: "/configuration/bodystyle",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddBodyStyle")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/bodystyle/:id",
    component: lazy(() =>
      import("../views/configuration/admin-configuration/AddBodyStyle")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
    privileges: [ApplicationPrivileges.ConfigurationView],
  },
  {
    path: "/configuration/minimum-finance-rate",
    component: lazy(() => import("../views/configuration/MinimumFinanceRate")),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/specification-categories",
    component: lazy(() =>
      import("../views/configuration/specifications/index")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/specification-category/:id",
    component: lazy(() =>
      import("../views/configuration/specifications/AddEditSpecification")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/specification-category",
    component: lazy(() =>
      import("../views/configuration/specifications/AddEditSpecification")
    ),
    exact: true,
    roles: [ApplicationRoles.PETROMIN_ADMIN],
  },
  {
    path: "/package-selection/:id",
    component: lazy(() => import("../views/package-selection/index")),
    exact: true,
    roles: [ApplicationRoles.DMS_ADMIN],
  },
  {
    path: "**",
    exact: true,
    component: lazy(() => import("../views/public/PageNotFound")),
  },
];

export default routes;
