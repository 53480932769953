import moment from "moment";

// default selected city
export const defaultCity = "Jeddah";

// language obj to check selected language condition to apply css - used in App.js
export const languages = {
  eng: 1,
  ar: 2,
};

export const menuHeaderIds = {
  PreOwnedCars: 1,
  NewCars: 2,
  SellItForMe: 3,
  Services: 4,
  MyCarValue: 5,
  PetrominConcierge: 7,
};

export const mediaUrlConstants = {
  Facebook: "FB",
  Instagram: "INSTAGRAM",
  LinkedIn: "LINKEDIN",
  twitter: "TWITTER",
  whatsApp: "WA",
  Email: "MAIL",
  YouTube: "YOUTUBE",
};

export const ConfigConstants = {
  NetworkError: "Network Error",
  ErrorDetails: " \n\n error details: ",
  serverError: "Internal server error on API:",
  refreshError: "OOPS! Something went wrong, Try refreshing the page ",
};

export const ConfigurationKeys = {
  InspectionFees: "InspectionFees",
  AllowedVehicleAge: "AllowedVehicleAge",
  MaxVehicleMileage: "MaxVehicleMileage",
  DelistAfterDays: "DelistAfterDays",
  TopCities: "TopCities",
  TopBrands: "TopBrands",
  MaxPriceInVehicleFilter: "MaxPriceInVehicleFilter",
  MinPriceInVehicleFilter: "MinPriceInVehicleFilter",
  MaxAskingPrice: "MaxAskingPrice",
  MinAskingPrice: "MinAskingPrice",
  CustomerSupportGeneralEmail: "CustomerSupportGeneralEmail",
  CustomerSupportInspectionEmail: "CustomerSupportInspectionEmail",
  CustomerEmail: "CustomerEmail",
  QualityCheckEmailAddress: "QualityCheckEmailAddress",
  AutoCareCustomerSupportEmailId: "AutoCareCustomerSupportEmailId",
  BodyAndPaintCustomerSupportEmailId: "BodyAndPaintCustomerSupportEmailId",
  AutoCareBookNowToEmailId: "AutoCareBookNowToEmailId",
  BodyAndPaintBookNowToEmailId: "BodyAndPaintBookNowToEmailId",
  CareersEmailId: "CareersEmailId",
  VATPercentage: "VATPercentage",
  IsCRAddressVerificationRequired: "IsCRAddressVerificationRequired",
  IsPayfortEnabled: "IsPayfortEnabled",
  MinMonthlyEMI: "MinMonthlyEMI",
  MaxMonthlyEMI: "MaxMonthlyEMI",
  ELMOTPLength: "ELMOTPLength",
  AllowedDaysForVASPurchaseForQuotation:
    "AllowedDaysForVASPurchaseForQuotation",
  CheckAWSPriceLowThreshold: "checkAWSPriceLowThreshold",
  CheckAWSPriceHighThreshold: "checkAWSPriceHighThreshold",
  DateFormat: "DateFormat",
  DateTimeFormat: "DateTimeFormat",
  EMICalculatorURL: "EMICalculatorURL",
  IsSTCPay: "IsSTCPay",
  AllowedDocumentFormats: "AllowedDocumentFormats",
  AllowedImageFormats: "AllowedImageFormats",
  MaxDocumentSizeInMB: "MaxDocumentSizeInMB",
  MaxImageSizeInMB: "MaxImageSizeInMB",
  MaxVideoSize: "MaxVideoSize",
  MinimumFinanceRateForUsedCars: "MinimumFinanceRateForUsedCars",
  MinimumFinanceRateForNewCars: "MinimumFinanceRateForNewCars",
};

export const ConfigurationTabs = {
  Configurations: "Configurations",
  Promotions: "Promotions",
  Packages: "Packages",
  B2CPackages: "B2CPackages",
  Services: "Services",
  Status: "Status",
  Template: "Template",
  BannerImage: "BannerImage",
  ManageDistributor: "ManageDistributor",
  ManageImporter: "ManageImporter",
};

export const NotificationTemplates = Object.freeze({
  Email: "Email",
  SMS: "SMS",
  Alert: "Alert",
  Whatsapp: "Whatsapp",
  Mobile: "Mobile",
});

export const qcTabList = [
  { icon: "dashboard", label: "Dashboard" },
  { icon: "myVehicle", label: "My Vehicles" },
];
export const EmailTemplateType = {
  HtmlSkeleton: "HtmlSkeleton",
  Header: "Header",
  Footer: "Footer",
};
//Vehicle details tab list Menu constant
export const vehicleDetailsTabMenu = [
  "Vehicle Image",
  "Vehicle Documents",
  "Vehicle Videos",
  // "Inspection Report"
];
export const vehicleDetailsTabMenuTranslation = [
  "LBL_VEHICLE_IMAGE",
  "LBL_VEHICLE_DOCUMENTS",
  "LBL_VEHICLE_VIDEOS",
  // "LBL_INSPECTION_REPORT",
];

//Status constant
export const status = ["Rejected", "Approved"];

//QC Status enum
export const QCStatus = {
  Approved: "Approved",
  Rejected: "Rejected",
};

// listing page constant
export const listRowDropdownOption = [
  { id: 1, value: "6" },
  { id: 2, value: "9" },
  { id: 3, value: "12" },
  { id: 4, value: "15" },
];

//Tab list Menu Enum
export const tabMenu = {
  VehicleImage: "Vehicle Image",
  InspectionReport: "Inspection Report",
  VehicleDocuments: "Vehicle Documents",
  VehicleVideos: "Vehicle Videos",
};

export const messages = {
  SaveSuccessful: "Saved Successfully",
  Approved: "Request Approved Successfully",
  Rejected: "Request Rejected Successfully",
};

export const UserCreationFormFields = {
  EmailId: "emailId",
  FirstName: "firstName",
  MiddleName: "middleName",
  LastName: "lastName",
  MobileNumber: "mobileNumber",
  AlternateNumber: "alternateNumber",
  PrimaryEmailId: "primaryEmailId",
  AlternateEmailId: "alternateEmailId",
};

export const CommunicationType = {
  WhatsApp: "Whatsapp",
  Email: "Email",
  Mobile: "Mobile",
};

export const PreferredLanguage = {
  English: "1",
  Arabic: "2",
};

export const downloadFileFormatExcel = ".xlsx";
export const downloadFileFormatCSV = ".csv";

export const excelFileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

export const csvFileType = "text/csv;charset=utf-8";

export const Reports = {
  AnalystDetails: "AnalystDetails_Report",
  CRMSalesPerson: "Users_CRMSalesPerson_Report",
  DMSSalesPerson: "Users_DMSSalesPerson_Report",
  QCAnalyst: "Users_QCAnalyst_Report",
  Users: "Users_Report",
  PendingDealerApplication: "Pending_Dealer_Application_Report",
  VehicleAssignedPendingAction: "Vehicle_Assigned_Pending_Action_Report",
  LeadPendingAction: "Lead_Pending_Action_Report",
  DMSLeads: "DMS_Leads",
  DealerList: "Dealer_List",
  Invoice: "Invoice",
  BulkBatchReport: "Batch_Report",
  VehicleHistory: "Vehicle_History",
  PromotionList: "PromotionList",
  QuotationList: "QuotationList",
  DeliveryNote: "DeliveryNote",
  Reports: "Reports",
  RVVehicleReport: "RV_Vehicle_Report",
  TranslationSample: "TranslationsSampleFile",
  Document: "Document",
  ProductCatalogueSample: "ProductCatalogueSampleFile",
  CustomerCareExecutive: "Users_CustomerCareExecutive_Report",
  SalesExecutive: "Users_SalesExecutive_Report",
  LeadsDetails: "Leads_Details",
  CreditNote: "Credit_Note",
};

export const VehicleListingSource = {
  SellItForMe: "SellItForMe",
  ListItForMe: "ListItForMe",
};

export const UploadVehicleConstants = {
  Excellent: "Excellent",
  Good: "Good",
  Standard: "Standard",
  Fair: "Fair",
  excelFileType:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  csvFileType: "text/csv",
};
//current width
export const currentWidth = window.screen.width;

export const masterConfigurationConstant = {
  TOP_CITIES: "topcities",
  TOP_BRANDS: "topbrands",
  ALLOWED_VEHICLE_AGE: "allowedvehicleage",
  MAX_VEHICLE_MILEAGE: "maxvehiclemileage",
  INSPECTION_FEES: "inspectionfees",
  DELIST_AFTER_DAYS: "delistafterdays",
  PRICE_CONFIGURATION: "priceconfiguration",
  EMAIL_CONFIGURATION: "emailconfiguration",
  EMI_CONFIGURATION: "emiconfiguration",
  MIN_MAX_EMI_CONFIGURATION: "minmaxemiconfiguration",
  VERBOSE_CONFIGURATION: "verboseconfiguration",
  SYSTEM_CONFIGURATION: "systemconfiguration",
  EMI_CALCULATOR_URL: "emicalculatorurl",
  MOBILE_FORCE_UPDATE: "MobileForceUpdate",
  IS_MOBILE_APP_DOWN: "IsMobileAppDown",
  STC_PAY: "isstcpay",
  MINIMUM_FINANCE_RATE_USED_CAR: "minimumfinancerateforusedcars",
  MINIMUM_FINANCE_RATE_NEW_CAR: "minimumfinanceratefornewcars",
};

export const masterConfigurationPriceConstant = [
  "MaxPriceInVehicleFilter",
  "MinPriceInVehicleFilter",
  "MaxAskingPrice",
  "MinAskingPrice",
  "MinMonthlyEMI",
  "MaxMonthlyEMI",
];

export const masterConfigurationEMIConstant = [
  "MinMonthlyEMI",
  "MaxMonthlyEMI",
];

export const masterConfigurationEmailConstant = [
  "CustomerSupportGeneralEmail",
  "CustomerSupportInspectionEmail",
  "CustomerEmail",
  "QualityCheckEmailAddress",
  "AutoCareCustomerSupportEmailId",
  "BodyAndPaintCustomerSupportEmailId",
  "AutoCareBookNowToEmailId",
  "BodyAndPaintBookNowToEmailId",
  "CareersEmailId",
];

// constant for image ArtifactType
export const ArtifactType = {
  PROFILE_VIEW: "profile-view",
  INSPECTION_PROFILE_VIEW: "inspection-profile-view",
  SPIN_CAR_360_VIEW: "spincar-360-view",
  SPIN_CAR_PROFILE_VIEW: "spincar-profile-view",
  MFG_PROFILE_VIEW: "mfg-profile-view",
};

export const QCVehicleStatus = {
  QC_ASSIGNED: "QCAssigned",
  QC_INPROGRESS: "QCInProgress",
  QC_REJECTED: "QCRejected",
  QC_UNASSIGNED: "QCUnassigned",
  QC_PASSED: "QCPassed",
};

export const SalesPersonStats = {
  VEHICLE_ASSIGNED: "Vehicles Assigned",
  TOTAL_LEAD_ASSIGNED: "Total Leads Assigned",
  RESPONSES_RECEIVED_LEADS: "Responses Received-Leads",
  RESPONSES_RECEIVED_VEHICLES: "Responses Received-Vehicles",
};

export const dmsAdminTabList = [
  "Dashboard",
  "Vehicle View",
  "Salesperson View",
  "Lead View",
  "Package Information",
  "Queries",
];

export const routesDependentComponents = {
  QC_ADMIN_DASHBOARD: "qcAdminDashboard",
  DMS_ADMIN_DASHBOARD: "DMSAdminDashboard",
};

//Country
//For Saudi Arabia Country = "sa", for india = "in"
export const Country = "sa";

export const MessageCodes = {
  EMAIL_ALREADY_EXISTS: "EMAIL_ALREADY_EXISTS",
  MOBILE_ALREADY_EXISTS: "MOBILE_ALREADY_EXISTS",
  USER_CREATED: "USER_CREATED",
  FAILED_TO_CREATE_USER: "FAILED_TO_CREATE_USER",
  SAVE_ROLE_KEY_ALREADY_EXSISTS: "SAVE_ROLE_KEY_ALREADY_EXSISTS",
};
export const sortByOptions = [
  { id: 1, key: "Price", translationKey: "PRICE", value: "AskingPrice" },
  {
    id: 2,
    key: "Updated Date",
    translationKey: "UPDATED_DATE",
    value: "UpdatedDate",
  },
];
export const GridPageSize = 6;

export const dealerSubmitFormConstants = {
  ApplicationSource: "OnboardingFormalDMS",
  OnlyLetters: /^[a-zA-Z ]*$/,
  OnlyLettersAndDigits: /^[a-zA-Z0-9]*$/,
  OnlyNineDigits: /^\d{12}$/,
};

export const dealerApplicationStatus = {
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  CANCELLED: "Cancelled",
};

export const profileRequestStatus = {
  APPROVED: "Approved",
  REJECTED: "Rejected",
};

export const ProfileFieldKey = {
  EMAIL_ADDRESS: "EmailAddress",
  MOBILE_NUMBER: "MobileNumber",
  FIRST_NAME: "FirstName",
  MIDDLE_NAME: "MiddleName",
  LAST_NAME: "LastName",
  ALTERNATE_EMAIL_ADDRESS: "AlternateEmailAddress",
  ALTERNATE_MOBILE_NUMBER: "AlternateMobileNumber",
};

export const VehicleListingStatusKey = {
  LISTED: "Listed",
  AWAIT_LISTING: "AwaitedListing",
  INSPECTION_READY: "InspectionReady",
  SOLD: "Sold",
  DELISTED: "Delisted",
  INSPECTION_REJECTED: "InspectionRejected",
  QUALITY_CHECK_READY: "QualityCheckReady",
  QC_REQUEST_FOR_A_CHANGE: "QCRequestForAChange",
  SAVED: "Saved",
  AWAITED_RV_APPROVAL: "AwaitedRVApproval",
  LISTED_INITIATED_FOR_UPGRADE: "ListedInitiatedForUpgrade",
  LISTED_SURVEY_READY_FOR_UPGRADE: "ListedSurveyReadyForUpgrade",
  LISTED_SURVEY_REJECTED_FOR_UPGRADE: "ListedSurveyRejectedForUpgrade",
  LISTED_QC_READY_FOR_UPGRADE: "ListedQCReadyForUpgrade",
  LISTED_QC_REQUEST_FOR_A_CHANGE_FOR_UPGRADE:
    "ListedQCRequestForAChangeForUpgrade",
  LISTED_QC_REJECTED_FOR_UPGRADE: "ListedQCRejectedForUpgrade",
  LISTED_READY_FOR_RV: "ListedReadyForRV",
};

export const UserActivationStatus = {
  ACTIVE: "Active",
  DEACTIVE: "Deactivated",
};

export const AssignmentModalType = {
  DMS_SALESPERSON: "DMS_SALESPERSON",
  QC_ANALYST: "QC_ANALYST",
};

export const FilterType = {
  CITY_ID: "CityId",
  OWNERSHIP_ID: "OwnershipId",
  TRANSMISSION_ID: "TransmissionId",
  BODY_TYPE_ID: "BodyTypeId",
  VEHICLE_MAKE_ID: "VehicleMakeId",
  VEHICLE_MODEL_ID: "VehicleModelId",
  EXTERIOR_COLOR_ID: "ExteriorColorId",
  INTERIOR_COLOR_ID: "InteriorColorId",
  DEALER_USER_ID: "DealerUserId",
  ANALYST_USER_ID: "AnalystUserId",
  ASSIGNMENT_DATE: "AssignmentDate",
  VEHICLE_CREATED_DATE: "VehicleCreatedDate",
  FUEL_TYPE_ID: "FuelTypeId",
  MANUFACTURE_YEAR: "ManufactureYear",
  ALL: "All",
  DEALER_ID: "DealerId",
  VEHICLE_AGE: "VehicleAge",
  UNASSIGNED: "Unassigned",
  MILEAGE: "Mileage",
  ASKING_PRICE: "AskingPrice",
};

export const SortType = {
  ASC: "asc",
  DESC: "desc",
};

//Query assignment type constant
export const QueryAssignmentType = {
  DMS: "DMS",
  CRM: "CRM",
};

export const summaryMenuIds = {
  Overview: "#overview",
  Specification: "#specifications",
  Features: "#features",
};

export const packagesTabList = [
  { key: "PackagesOfferedBy", value: "Packages Offered By" },
  { key: "CurrentPackages", value: "Current Packages" },
  { key: "PackageHistory", value: "Package History" },
];

export const packagesTabConstant = {
  PackagesOfferedBy: "Offers",
  CurrentPackages: "Packages",
  PackageHistory: "History",
};

export const VehicleListingStatuses = {
  Saved: 1,
  InspectionReady: 2,
  InspectionRejected: 3,
  QualityCheckReady: 4,
  QCRequestForAChange: 5,
  Listed: 6,
  Sold: 7,
  Deleted: 8,
  Delisted: 9,
  AwaitedListing: 10,
  AwaitedRVApproval: 11,
  QCRejected: 12,
};

export const DMSStatusFilter = [
  {
    StatusCode: "Saved",
    StatusCodeDisplayValue: "DMS_FILTER_SAVED",
  },
  {
    StatusCode: "InspectionReady",
    StatusCodeDisplayValue: "DMS_FILTER_INSPECTION_READY",
  },
  {
    StatusCode: "InspectionRejected",
    StatusCodeDisplayValue: "DMS_FILTER_INSPECTION_REJECTED",
  },
  {
    StatusCode: "QualityCheckReady",
    StatusCodeDisplayValue: "DMS_FILTER_QUALITY_CHECK_READY",
  },
  {
    StatusCode: "QCRequestForAChange",
    StatusCodeDisplayValue: "DMS_FILTER_QC_REQUEST_FOR_A_CHANGE",
  },
  {
    StatusCode: "Listed",
    StatusCodeDisplayValue: "DMS_FILTER_LISTED",
  },
  {
    StatusCode: "Delisted",
    StatusCodeDisplayValue: "DMS_FILTER_DELISTED",
  },
  {
    StatusCode: "Sold",
    StatusCodeDisplayValue: "DMS_FILTER_SOLD",
  },
  {
    StatusCode: "AwaitedListing",
    StatusCodeDisplayValue: "DMS_FILTER_AWAIT_LISTING",
  },
  {
    StatusCode: "PendingInspectionGreaterThan1Day",
    StatusCodeDisplayValue: "DMS_FILTER_PENDING_INSPECTION",
  },
  {
    StatusCode: "VehicleListedNoActionGreaterThan30Days",
    StatusCodeDisplayValue: "DMS_FILTER_NO_VEHICLE_ACTION",
  },
  {
    StatusCode: "Unassigned",
    StatusCodeDisplayValue: "DMS_FILTER_UNASSIGNED",
  },
];

export const DMSSalespersonStatusFilter = [
  {
    StatusCode: "Saved",
    StatusCodeDisplayValue: "DMS_FILTER_SAVED",
  },
  {
    StatusCode: "InspectionReady",
    StatusCodeDisplayValue: "DMS_FILTER_INSPECTION_READY",
  },
  {
    StatusCode: "InspectionRejected",
    StatusCodeDisplayValue: "DMS_FILTER_INSPECTION_REJECTED",
  },
  {
    StatusCode: "QualityCheckReady",
    StatusCodeDisplayValue: "DMS_FILTER_QUALITY_CHECK_READY",
  },
  {
    StatusCode: "QCRequestForAChange",
    StatusCodeDisplayValue: "DMS_FILTER_QC_REQUEST_FOR_A_CHANGE",
  },
  {
    StatusCode: "Listed",
    StatusCodeDisplayValue: "DMS_FILTER_LISTED",
  },
  {
    StatusCode: "Delisted",
    StatusCodeDisplayValue: "DMS_FILTER_DELISTED",
  },
  {
    StatusCode: "Sold",
    StatusCodeDisplayValue: "DMS_FILTER_SOLD",
  },
  {
    StatusCode: "AwaitedListing",
    StatusCodeDisplayValue: "DMS_FILTER_AWAIT_LISTING",
  },
  {
    StatusCode: "PendingInspectionGreaterThan1Day",
    StatusCodeDisplayValue: "DMS_FILTER_PENDING_INSPECTION",
  },
  {
    StatusCode: "VehicleListedNoActionGreaterThan30Days",
    StatusCodeDisplayValue: "DMS_FILTER_NO_VEHICLE_ACTION",
  },
];

export const POAdminFilters = [
  {
    VehicleListingStatusId: VehicleListingStatuses.Saved,
    StatusCode: "Saved",
    StatusCodeDisplayValue: "DMS_FILTER_SAVED",
  },
  {
    VehicleListingStatusId: VehicleListingStatuses.InspectionReady,
    StatusCode: "InspectionReady",
    StatusCodeDisplayValue: "DMS_FILTER_INSPECTION_READY",
  },
  {
    VehicleListingStatusId: VehicleListingStatuses.InspectionRejected,
    StatusCode: "InspectionRejected",
    StatusCodeDisplayValue: "DMS_FILTER_INSPECTION_REJECTED",
  },
  {
    VehicleListingStatusId: VehicleListingStatuses.QualityCheckReady,
    StatusCode: "QualityCheckReady",
    StatusCodeDisplayValue: "DMS_FILTER_QUALITY_CHECK_READY",
  },
  {
    VehicleListingStatusId: VehicleListingStatuses.QCRequestForAChange,
    StatusCode: "QCRequestForAChange",
    StatusCodeDisplayValue: "DMS_FILTER_QC_REQUEST_FOR_A_CHANGE",
  },
  {
    VehicleListingStatusId: VehicleListingStatuses.Listed,
    StatusCode: "Listed",
    StatusCodeDisplayValue: "DMS_FILTER_LISTED",
  },
  {
    VehicleListingStatusId: VehicleListingStatuses.Sold,
    StatusCode: "Sold",
    StatusCodeDisplayValue: "DMS_FILTER_SOLD",
  },
  {
    VehicleListingStatusId: VehicleListingStatuses.Delisted,
    StatusCode: "Delisted",
    StatusCodeDisplayValue: "DMS_FILTER_DELISTED",
  },
  {
    VehicleListingStatusId: VehicleListingStatuses.AwaitedListing,
    StatusCode: "AwaitedListing",
    StatusCodeDisplayValue: "DMS_FILTER_AWAIT_LISTING",
  },
];

export const VehicleAgeFilters = [
  {
    AgeCode: "0,10",
    AgeCodeDisplayValue: "VEHICLE_AGE_FILTER_0_TO_10_DAYS",
  },
  {
    AgeCode: "10,20",
    AgeCodeDisplayValue: "VEHICLE_AGE_FILTER_10_TO_20_DAYS",
  },
  {
    AgeCode: "20,30",
    AgeCodeDisplayValue: "VEHICLE_AGE_FILTER_20_TO_30_DAYS",
  },
  {
    AgeCode: "30,40",
    AgeCodeDisplayValue: "VEHICLE_AGE_FILTER_30_TO_40_DAYS",
  },
  {
    AgeCode: "40,50",
    AgeCodeDisplayValue: "VEHICLE_AGE_FILTER_40_TO_50_DAYS",
  },
  {
    AgeCode: "50,60",
    AgeCodeDisplayValue: "VEHICLE_AGE_FILTER_50_TO_60_DAYS",
  },
  {
    AgeCode: "60",
    AgeCodeDisplayValue: "VEHICLE_AGE_FILTER_GREATER_THAN_60_DAYS",
  },
];

export const UserPieChartUniqueConstant = 999;

export const StatusTypes = {
  LISTING: "Listing",
  QC: "QC",
  INSPECTION: "Inspection",
  DMS: "DMS",
};

//spinning vehicle constant
export const SpinCar360Parameters = "!disableautospin!hidecarousel";

export const VehicleStatus = {
  InspectionReady: "InspectionReady",
  InspectionRejected: "InspectionRejected",
  QualityCheckReady: "QualityCheckReady",
  QCRequestForAChange: "QCRequestForAChange",
  Listed: "Listed",
  Sold: "Sold",
  AwaitedListing: "AwaitedListing",
  PendingInspectionGreaterThan1Day: "PendingInspectionGreaterThan1Day",
  VehicleListedNoActionGreaterThan30Days:
    "VehicleListedNoActionGreaterThan30Days",
  Unassigned: "Unassigned",
  QcPassed: "QC Passed",
  QcRejected: "QC Rejected",
  QcAssigned: "QC Assigned",
  Saved: "Saved",
  QcUnAssigned: "QC Unassigned",
};

export const profileArtiFactTypes = [
  "profile-view",
  "inspection-profile-view",
  "spincar-profile-view",
];

export const DMSAdminCardLabel = {
  TOTAL_CREDIT_COUNT: "TotalCreditCount",
  TOTAL_ACTIVE_PACKAGE: "TotalActivePackage",
  TOTAL_LISTED_VEHICLE: "TotalListedVehicle",
};

export const POAdminCardLabel = {
  TOTAL_ACTIVE_B2B_PACKAGE: "TotalActiveB2BPackage",
  TOTAL_ACTIVE_B2C_PACKAGE: "TotalActiveB2CPackage",
  TOTAL_PENDING_DMS_REQUEST: "TotalPendingDMSRequest",
};

export const InspectorCardLabel = {
  TOTAL_INSPECTION_READY: "TotalInspectionReady",
};

export const DMSSalespersonCardLabel = {
  TOTAL_LISTED_VEHICLE: "TotalListedVehicle",
};
export const VehicleListingWorkflowNumber = {
  Saved: 1,
  InspectionReady: 2,
  AwaitedRVApproval: 2,
  InspectionRejected: 3,
  QualityCheckReady: 4,
  QCRequestForAChange: 5,
  QCRejected: 6,
  AwaitedListing: 7,
  Listed: 8,
  Sold: 9,
  Deleted: 10,
  Delisted: 11,
};

export const QC_IMAGE_MIN_VALIDATION_COUNT = 2;
export const QC_THREE_IMAGE_VALIDATION_COUNT = 3;
export const QC_IMAGE_MIN_UPPER_RANGE_VALIDATION_COUNT = 4;

export const languageConst = {
  ENGLISH: "English",
  ARABIC: "عربي",
};

export const feedBackRating = [1, 2, 3, 4, 5];

export const OtherColorHexCode = "######";

export const months = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
};

export const ConfigurationStatus = [
  {
    label: "ACTIVE",
    value: true,
  },
  {
    label: "INACTIVE",
    value: false,
  },
  {
    label: "ALL",
    value: "",
  },
];

export const feedBackRatingArr = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
];

export const feedBackArtifactType = {
  FEEDBACK_IMAGE: "feedback-image",
  FEEDBACK_VIDEO: "feedback-video",
};

export const feedBackUser = {
  SELLER_BUYER_IMMEDIATE: "SellerBuyerImmediate",
  SELLER_BUYER_POSTSELL: "SellerBuyerPostSell",
  GUEST_USER: "GuestUser",
  PORTAL_USER: "PortalUser",
};

export const FailedInvoiceStatus = ["ChargeFailed", "Pending"];

export const ProfileChangeMessageCodes = {
  EMAIL_ALREADY_EXISTS: "EMAIL_ALREADY_EXISTS",
  ALTERNATEEMAIL_ALREADY_EXISTS: "ALTERNATEEMAIL_ALREADY_EXISTS",
  MOBILENUMBER_ALREADY_EXISTS: "MOBILENUMBER_ALREADY_EXISTS",
  ALTERNATEMOBILENUMBER_ALREADY_EXISTS: "ALTERNATEMOBILENUMBER_ALREADY_EXISTS",
  PROFILE_REQUEST_APPROVED: "PROFILE_REQUEST_APPROVED",
  PROFILE_REQUEST_NOT_APPROVED: "PROFILE_REQUEST_NOT_APPROVED",
  REJECT_PROFILE_REQUEST_SUCCESSFUL: "REJECT_PROFILE_REQUEST_SUCCESSFUL",
  REJECT_PROFILE_REQUEST_NOT_SUCESSFUL: "REJECT_PROFILE_REQUEST_NOT_SUCESSFUL",
};

export const PackageSubscriptionStatus = {
  Active: "Active",
  Pending: "Pending",
};

export const Durations = {
  Annual: "Annual",
  OneTime: "OneTime",
};

export const B2CPackageConfigurationStatus = {
  Saved: "Saved",
  Active: "Active",
  Deactive: "Deactive",
  Delete: "Delete",
};

export const IsVehicleLinked = {
  Yes: "Yes",
  No: "No",
};

export const externalUserType = {
  Customer: "CUSTOMER",
  Dealer: "DEALER",
};
export const userType = {
  Customer: "Customer",
  CustomerAr: "العميل",
  DMSAdmin: "DMSAdmin",
  DMSSalesPerson: "DMSSalesPerson",
  QCAnalyst: "QCAnalyst",
};

export const PackageServiceMessageCode = {
  DUPLICATE_B2CPACKAGENAME: "DUPLICATE_B2CPACKAGENAME",
  DUPLICATE_SERVICENAME: "DUPLICATE_SERVICENAME",
};
export const FileType = [
  {
    label: "CSV",
    value: "csv",
  },
  {
    label: "XLSX",
    value: "xlsx",
  },
];

export const ImageArtifactKey = {
  ProfileView: "profile-view",
  RightSide: "right-side",
  LeftSide: "left-side",
  FrontView: "front-view",
  Boot: "boot",
  FrontWindScreen: "front-windscreen",
  Dashboard: "dashboard",
  GearShift: "gear-shift",
  Odometer: "odometer",
  ClutchPedal: "abc-pedal",
  FrontSeat: "car-front-seat",
  BackSeat: "car-back-seats",
  RearWindScreen: "rearwind-screen",
  RearView: "rear-view",
  SpinProfileView: "spincar-profile-view",
  Spin360View: "spincar-360-view",
  DmgEvidenceBefore: "Dmg-Evidence-Before",
  DmgEvidenceAfter: "Dmg-Evidence-After",
  EBrochure: "e-brochure",
  Video: "vehicle-video",
  VehicleImage: "vehicle-image",
};

export const InspectionArtifactCategory = {
  VehicleListingInspectionImage: "VehicleListingInspectionImage",
  VehicleListingSpinCarImage: "VehicleListingSpinCarImage",
  VehicleListingSpinCar360: "VehicleListingSpinCar360",
  VehicleSupplierImage: "VehicleSupplierImage",
  VehicleListingImage: "VehicleListingImage",
  VehicleDmgEvidence: "VehicleDmgEvidence",
  VehicleEBrochure: "VehicleEBrochure",
};

export const SuccessStatus = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const cmsConfigurationConstant = {
  BANNER_IMAGE_WEB: "BannerImageWeb",
  BANNER_IMAGE_MOBILE: "BannerImageMobile",
  DEFAULT_BANNER_WEB: "DefaultBannerWeb",
  DEFAULT_BANNER_MOBILE: "DefaultBannerMobile",
  TextConfiguration: "TextConfiguration",
  QCRequestForChangeEdit: "QCRequestForChangeEdit",
};

export const convertToLocalDate = (value) => {
  const utcDate = moment.utc(value);
  const localDate = moment(utcDate).local().format("MMM DD,YYYY hh:mm A");
  return localDate;
};

export const tabItems = [
  { label: "QUOTATION_STATUS", value: "Quotation" },
  { label: "LBL_QC_REJECTION_REASONS", value: "QCRejection" },
  { label: "LOCATION_DETAILS", value: "LocationDetails" },
  { label: "WORKING_SECTOR", value: "WorkingSector" },
  { label: "BANK_DETAILS", value: "BankDetails" },
  { label: "ROLES", value: "Roles" },
  { label: "PRIVILEGES", value: "Privileges" },
  { label: "FAQ", value: "Faq" },
  { label: "TRANSLATIONS", value: "Translation" },
  { label: "BODY_STYLE", value: "BodyStyle" },
];

export const selectedAdminConfig = {
  Quotation: "Quotation",
  QCRejection: "QCRejection",
  LocationDetails: "LocationDetails",
  Roles: "Roles",
  WorkingSector: "WorkingSector",
  BankDetails: "BankDetails",
  Privileges: "Privileges",
  Faq: "Faq",
  Translation: "Translation",
  Suppliers: "Suppliers",
  BodyStyle: "BodyStyle",
};

export const TemplateItems = [
  { label: "LBL_EMAIL_TEMPLATE", value: "EmailTemplate" },
];

export const selectedTemplate = {
  EmailTemplate: "EmailTemplate",
  LocationDetails: "LocationDetails",
};

export const QCRejectionStatusMessageCode = {
  QUALITY_CHECK_REJECTION_REASON_ALREADY_EXISTS:
    "QUALITY_CHECK_REJECTION_REASON_ALREADY_EXISTS",
  QUALITY_CHECK_REJECTION_REASON_SAVE_SUCCESS:
    "QUALITY_CHECK_REJECTION_REASON_SAVE_SUCCESS",
  QUALITY_CHECK_REJECTION_REASON_SAVE_FAILED:
    "QUALITY_CHECK_REJECTION_REASON_SAVE_FAILED",
  QUALITY_CHECK_REJECTION_REASON_INVALID_STATUS_DETAILS:
    "QUALITY_CHECK_REJECTION_REASON_INVALID_STATUS_DETAILS",
};

export const QuotationStatusMessageCode = {
  QUOTATIONSTATUS_SAVE_FAILURE_DUPLICATE_QUOTATIONSTATUS:
    "QUOTATIONSTATUS_SAVE_FAILURE_DUPLICATE_QUOTATIONSTATUS",
  QUOTATIONSTATUS_UPDATE_FAILURE_DUPLICATE_QUOTATIONSTATUS:
    "QUOTATIONSTATUS_UPDATE_FAILURE_DUPLICATE_QUOTATIONSTATUS",
  QUOTATIONSTATUS_SAVE_FAILURE: "QUOTATIONSTATUS_SAVE_FAILURE",
};

export const valueTypes = {
  Boolean: "Boolean",
  String: "String",
  Int: "Int",
};

export const DealerCategoryType = {
  STANDARD: "Standard",
};

export const ManufactureArtifactKey = {
  ProfileView: "mfg-profile-view",
  RightSide: "mfg-right-side",
  LeftSide: "mfg-left-side",
  FrontView: "mfg-front-view",
  Boot: "mfg-boot",
  FrontWindScreen: "mfg-front-windscreen",
  Dashboard: "mfg-dashboard",
  GearShift: "mfg-gear-shift",
  Odometer: "mfg-odometer",
  ClutchPedal: "mfg-abc-pedal",
  FrontSeat: "mfg-car-front-seat",
  BackSeat: "mfg-car-back-seats",
  RearWindScreen: "mfg-rearwind-screen",
  RearView: "mfg-rear-view",
  Video: "mfg-video",
  Document: "mfg-document",
  VehicleImage: "mfg-vehicle-image",
};

export const InspectionType = {
  IsInspectionAtHome: "IsInspectionAtHome",
  InspectionByPickup: "InspectionByPickup",
};

export const CountryCodes = [
  {
    code: "in",
    value: "+91",
    inputMask: "#00 000 0000",
  },
  {
    code: "sa",
    value: "+966",
    inputMask: "#00 000 000",
  },
];

export const getCountryCodes = () => {
  const allowedCountryCodes = (process.env.REACT_APP_COUNTRY_CODES || "").split(
    "|"
  );
  return CountryCodes.filter((x) => allowedCountryCodes.includes(x.code));
};

export const apiDateFormat = "YYYY-MM-DD";

export const AddressType = {
  PETROMIN_LOCATION: "PetrominLocation",
};

export const LocationNameStatusMessageCode = {
  SAVE_LOCATION_DETAILS_ALREADY_EXSISTS:
    "SAVE_LOCATION_DETAILS_ALREADY_EXSISTS",
};

export const AutoDataMarketValueIndicators = {
  BELOW_MARKET_VALUE: "BELOW_MARKET_VALUE",
  ABOVE_MARKET_VALUE: "ABOVE_MARKET_VALUE",
  TOO_LOW_THAN_MARKET_PRICE: "TOO_LOW_THAN_MARKET_PRICE",
  TOO_HIGH_THAN_MARKET_PRICE: "TOO_HIGH_THAN_MARKET_PRICE",
};

export const RibbonType = {
  OUTLET: "OUTLET",
  NEW: "NEW",
  PRE_OWNED: "PRE OWNED",
  SELF_LISTED: "SELF LISTED",
};

export const ArtifactCategory = {
  VehicleListingImage: "VehicleListingImage",
  VehicleListingDocument: "VehicleListingDocument",
  ProfileImage: "ProfileImage",
  ProfileDocument: "ProfileDocument",
  PortalImage: "PortalImage",
  PortalVideo: "PortalVideo",
  SellerDocument: "SellerDocument",
  BuyerDocument: "BuyerDocument",
  FeedbackImage: "FeedbackImage",
  FeedbackVideo: "FeedbackVideo",
  SupportImage: "SupportImage",
  VehicleListingInspectionImage: "VehicleListingInspectionImage",
  VehicleListingSpinCarImage: "VehicleListingSpinCarImage",
  VehicleListingSpinCar360: "VehicleListingSpinCar360",
  VehicleSupplierImage: "VehicleSupplierImage",
  VehicleDmgEvidence: "VehicleDmgEvidence",
  VehicleEBrochure: "VehicleEBrochure",
};

export const CMSPageKey = {
  INFORMATION: "Information",
  HelpText: "HelpText",
};

export const DealerOnboardType = {
  BypassPackage: "BypassPackage",
  AutoReleaseVehicle: "AutoReleaseVehicle",
  GGMDigitalDealer: "GGMDigitalDealer",
};

export const templates = {
  FAQ: "FAQ",
};
export const InsuranceArtifacts = ["insurance", "third-party-insurance"];
export const VehicleRequiredDocs = ["rc", "bank-noc"];

export const MimeTypes = {
  ImagePNG: "image/png",
  ImageJPEG: "image/jpeg",
  ImageJPG: "image/jpg",
  ImageWebP: "image/webp",
  ImageBMP: "image/bmp",

  VideoMP4: "video/mp4",

  ApplicationPDF: "application/pdf",
  ApplicationDOCX:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ApplicationDOC: "application/msword",
};

export const ImageMimeTypes = [
  MimeTypes.ImagePNG,
  MimeTypes.ImageJPEG,
  MimeTypes.ImageJPG,
  MimeTypes.ImageWebP,
  MimeTypes.ImageBMP,
];
export const DocMimeTypes = [
  MimeTypes.ImagePNG,
  MimeTypes.ImageJPEG,
  MimeTypes.ImageJPG,
  MimeTypes.ApplicationPDF,
  MimeTypes.ApplicationDOCX,
  MimeTypes.ApplicationDOC,
];

export const BrochureMimeTypes = [
  MimeTypes.ImagePNG,
  MimeTypes.ImageJPEG,
  MimeTypes.ImageJPG,
  MimeTypes.ApplicationPDF,
  MimeTypes.VideoMP4,
];

export const VideoMimeTypes = [MimeTypes.VideoMP4];

export const FileTypeData = [
  { type: MimeTypes.ImagePNG, value: "PNG" },
  { type: MimeTypes.ImageJPEG, value: "JPEG" },
  { type: MimeTypes.ImageJPG, value: "JPG" },
  { type: MimeTypes.ImageWebP, value: "WebP" },
  { type: MimeTypes.ImageBMP, value: "BMP" },
  { type: MimeTypes.ApplicationPDF, value: "PDF" },
  { type: MimeTypes.ApplicationDOCX, value: "DOCX" },
  { type: MimeTypes.ApplicationDOC, value: "DOC" },
  { type: MimeTypes.VideoMP4, value: "MP4" },
];

export const MaxFileSize = 102400 * 100;

export const blobToBase64 = async (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

export const CMSLabelConstant = {
  BalloonPaymentHelpText: "BalloonPaymentHelpText",
  DownPaymentHelpText: "DownPaymentHelpText",
  ProfitRateHelpText: "ProfitRateHelpText",
  DurationOfLoanHelpText: "DurationOfLoanHelpText",
};

export const FeatureTypes = {
  VehicleProfile: "VehicleProfile",
  Make: "Make",
  Trim: "Trim",
  Model: "Model",
  VehicleListing: "VehicleListing",
};

export const PlatformType = {
  WEB: "Web",
  MOBILE: "Mobile",
};

export const IsFileValid = async (
  file,
  { checkFileSize = true, checkFileType = true, fileSize = 10, fileType = [] }
) => {
  let result = { isFileSizeValid: true, isFileTypeValid: true };

  if (checkFileSize) {
    const allowedImageSize = fileSize * 1048576;
    if (file.size > allowedImageSize) {
      result = { ...result, isFileSizeValid: false };
    } else {
      result = { ...result, isFileSizeValid: true };
    }
  }
  if (checkFileType) {
    if (!fileType.includes(file.type)) {
      result = { ...result, isFileTypeValid: false };
    } else {
      result = { ...result, isFileTypeValid: true };
    }
  }
  return result;
};
export const ReportTypeConstant = {
  Info: "Info",
  List: "List",
  PieChart: "Pie Chart",
  BarChart: "Bar Chart",
};

export const DocumentConstant = "Document";

export const updateType = [
  { id: 1, label: "IGNORE", value: "Ignore" },
  {
    id: 2,
    label: "LATER",
    value: "Later",
  },
  { id: 3, label: "FORCE", value: "Force" },
  {
    id: 4,
    label: "NOT_REQUIRED",
    value: "NotRequired",
  },
];

export const FeedBackStatusActionType = {
  New: "New",
};

export const ProductCatalogueFileType = [
  {
    label: "XLSX",
    value: "xlsx",
  },
];

export const SampleFilesTemplate = {
  MarkAsSold: "MarkAsSoldTemplate",
};

export const ListingDocumentArtifacts = [
  "rc",
  "insurance",
  "service-contract",
  "service-booklet",
  "extended-warranty",
  "bank-noc",
  "MVPI",
  "third-party-insurance",
];

export const VehicleType = [
  {
    label: "USED_CAR",
    value: "used",
  },
  {
    label: "NEW_OUTLET_CAR",
    value: "new",
  },
];

export const DownloadBulkUploadFileName = {
  newCar: "GGMNewOutletCarsVehicleUploadTemplate",
  usedCar: "GGMPreOwnedCarsVehicleUploadTemplate",
};

export const getFileTypeMessageString = (fileTypes) => {
  const fileType = FileTypeData.filter((x) => fileTypes.includes(x.type))
    .map((fileType) => fileType.value)
    .join(", ");
  return fileType;
};

export const VehicleFilterType = {
  Outlet: "Outlet",
  New: "New",
  PreOwned: "PreOwned",
  All: "All",
  SelfListed: "SelfListed",
};

// isFinance options
export const isFinanceOption = [
  { id: "Yes", value: true },
  { id: "No", value: false },
];

export const vehicleTypes = [
  {
    VehicleKey: "All",
    VehicleType: "ALL",
  },
  {
    VehicleKey: VehicleFilterType.New,
    VehicleType: "NEW",
  },
  {
    VehicleKey: VehicleFilterType.Outlet,
    VehicleType: "OUTLET",
  },
  {
    VehicleKey: VehicleFilterType.PreOwned,
    VehicleType: "PRE_OWNED",
  },
  {
    VehicleKey: VehicleFilterType.SelfListed,
    VehicleType: "SELF_LISTED",
  },
];

export const translationsLang = {
  English: "en",
  Arabic: "ar",
};
export const warrantyStatuses = {
  WarrantyPurchaseInitiated: "WarrantyPurchaseInitiated",
  WarrantyPaymentInProgress: "WarrantyPaymentInProgress",
  WarrantyPaymentSuccess: "WarrantyPaymentSuccess",
  WarrantyPaymentFailure: "WarrantyPaymentFailure",
  WarrantyInspectionReviewInProgress: "WarrantyInspectionReviewInProgress",
  WarrantyInspectionRejected: "WarrantyInspectionRejected",
  WarrantyInspectionApproved: "WarrantyInspectionApproved",
};
